import {Box, Button, Flex, Spacer, TableContainer, Text, useDisclosure, Wrap, WrapItem,} from '@chakra-ui/react';
import WATERMark from '../assets/watermark.png';
import {Structures} from './ColumnsTable';
import TableComponent from './Table';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {useEffect, useRef, useState} from 'react';
import {Data} from '../stores/data';
import {TumorSampleRegistrationProcess} from './TumorSampleRegistration/TumorSampleRegistrationProcess';
import {AlertConfirm} from './Alerts/AlertConfirm';
import {Search} from './Search';
import {MdLocalShipping} from 'react-icons/md';

const TumorSampleRegistrationPage = observer(
  ({height, dataStore}: { height: any; dataStore: Data }) => {
    const [t] = useTranslation();
    const [filter, setFilter] =
      useState<{ column: string; value: boolean; }>({column: 'isComplete', value: false});
    const [globalFilter, setGlobalFilter] = useState<unknown>();
    const {isOpen, onOpen, onClose} = useDisclosure();
    const cancelRef = useRef();

    const [, setTableState] = useState<any>();

    // const [open, setOpen] = useState(false);

    const [patientRegistrationProcess, setPatientRegistrationProcess] =
      useState<{ id?: number; open: boolean }>({open: false,});

    const [patientRegistrationId, setPatientRegistrationId] = useState<number>();

    const getTumorRegistrationList = () => {
      dataStore.fetchTumorRegistrations();
      setFilter({column: 'isComplete', value: false});
    };

    useEffect(() => {
      getTumorRegistrationList();
    }, [dataStore.isTumorRegistrationTable]);

    const getIsActiveFilter = (isCompletedValue: boolean | false) => {
      return filter.value === isCompletedValue;
    };

    const handlePatientRegistrationProcess = (open: boolean, id?: number) => {
      setPatientRegistrationProcess({open, id});
      dataStore.showTumorRegistrationTable(false);
    };

    const deleteTumorRegistration = async () => {
      if (patientRegistrationId) {
        await dataStore.deleteTumorRegistration(patientRegistrationId);
        await dataStore.fetchTumorRegistrations();
        onClose();
        setPatientRegistrationId(undefined);
      }
    };

    const onCloseAlert = () => {
      onClose();
      setPatientRegistrationId(undefined);
    };

    return (
      <>
        <Flex
          direction="row"
          height={height}
          backgroundImage={WATERMark}
          backgroundRepeat="no-repeat"
          backgroundPosition="90%"
          backgroundAttachment="inherit"
          backgroundSize="100%"
          style={{backgroundPositionY: 'bottom'}}
        >
          <Flex direction="column" flex={1} bgColor="#D4D9E7">
            <Box
              marginTop="15px"
              marginRight="20"
              marginLeft="20"
              marginBottom="25"
              border="1px"
              borderColor="#A0AEBE"
              shadow="lg"
              backgroundColor="white"
              overflowY="scroll"
            >
              {dataStore.isTumorRegistrationTable ? (
                <>
                  <Flex mt="7px">
                    <Wrap marginX={5} mb={18} mt={2}>
                      <Text
                        marginTop="15"
                        marginLeft="5"
                        textTransform="uppercase"
                        letterSpacing={0.5}
                        color="#5E5873"
                        fontSize="18"
                      >
                        {t('table.actions.tumorRegistration.title')}
                      </Text>
                    </Wrap>
                    <Spacer></Spacer>
                    <Wrap marginX={5} mt={2}>
                      <WrapItem h={12}>
                        <a
                          href={process.env.PUBLIC_URL + "/files/shipping_form_sdx.xlsx"}
                          download="Koeriers Aanvraagformulier Hartwig Medical Foundation.xlsx">
                          <Button
                            color="black"
                            bg={'white'}
                            boxShadow="md"
                            variant="outline"
                            borderColor="gray.300"
                            rightIcon={<MdLocalShipping/>}
                            fontSize={14}
                            h={10}
                            px={5}
                          >
                            {t('table.actions.tumorRegistration.downloadShippingForm')}
                          </Button>
                        </a>
                      </WrapItem>
                    </Wrap>
                    <Wrap marginX={5} mt={2}>
                      <WrapItem h={12}>
                        <a
                          href={process.env.PUBLIC_URL + "/files/instructionslides_registration_module_dutch.pdf"}
                          download="Instructieslides - registratiemodule.pdf">
                          <Button
                            color="black"
                            bg={'white'}
                            boxShadow="md"
                            variant="outline"
                            borderColor="gray.300"
                            fontSize={14}
                            h={10}
                            px={5}
                          >
                            {t('table.actions.tumorRegistration.help')}
                          </Button>
                        </a>
                      </WrapItem>
                    </Wrap>
                  </Flex>
                  <Flex>
                    <Wrap marginX={5} mt={2}>
                      <WrapItem>
                        <Button
                          color={getIsActiveFilter(false) ? 'white' : '#6E6B73'}
                          bg={getIsActiveFilter(false) ? '#304B99' : 'white'}
                          borderColor="gray.300"
                          boxShadow="md"
                          variant="outline"
                          fontSize={14}
                          h={10}
                          px={5}
                          onClick={() => {
                            setFilter({value: false, column: 'isComplete'});
                          }}
                        >
                          {t('table.actions.tumorRegistration.openRegistration')}
                        </Button>
                      </WrapItem>
                      <WrapItem>
                        <Button
                          color={getIsActiveFilter(true) ? 'white' : '#6E6B73'}
                          bg={getIsActiveFilter(true) ? '#304B99' : 'white'}
                          borderColor="gray.300"
                          boxShadow="md"
                          variant="outline"
                          fontSize={14}
                          h={10}
                          px={5}
                          onClick={() => {
                            setFilter({value: true, column: 'isComplete'});
                          }}
                        >
                          {t('table.actions.tumorRegistration.completeRegistration')}
                        </Button>
                      </WrapItem>
                    </Wrap>
                    <Spacer/>
                    <Wrap marginX={5} mt={2}>
                      <WrapItem h={12}>
                        <Button
                          color="black"
                          bg={'white'}
                          boxShadow="md"
                          variant="outline"
                          borderColor="gray.300"
                          fontSize={14}
                          h={10}
                          px={5}
                          onClick={() => handlePatientRegistrationProcess(true)}
                        >
                          {'+ ' + t('table.actions.tumorRegistration.newRegistration')}
                        </Button>
                      </WrapItem>
                    </Wrap>
                  </Flex>
                  <Flex>
                    <Wrap mx={5} mt={2} w="100%">
                      <Search
                        filter={globalFilter}
                        onChange={(v) => {
                          setGlobalFilter(v);
                        }}
                      />
                    </Wrap>
                  </Flex>
                  <TableContainer
                    marginTop="15"
                    marginRight="18"
                    marginLeft="18"
                    marginBottom="25"
                  >
                    <TableComponent
                      columns={{
                        structure: Structures.TUMOR_SAMPLES,
                        texts: 'table.tumorRegistration',
                      }}
                      getTableState={(state) => setTableState(state)}
                      addFilter={filter}
                      paginable={true}
                      data={dataStore.tumorRegistrations}
                      onClickContinue={(rowValues) => {
                        handlePatientRegistrationProcess(
                          true,
                          rowValues.original.tumorRegistrationId
                        );
                      }}
                      onClickDelete={(rowValues) => {
                        setPatientRegistrationId(
                          rowValues.original.tumorRegistrationId
                        );
                        onOpen();
                      }}
                      addGlobalFilter={globalFilter}
                      includeCheckboxes={false}
                      includeActionButton={true}
                      hiddenColumns={['isComplete']}
                      actionButtonType={
                        filter?.value === false || !filter?.value
                          ? ['EDIT', 'DELETE']
                          : ['DOWNLOAD']
                      }
                      sortBy={[{desc: true, id: 'startDate'}]}
                      onClickDownload={(row) =>
                        dataStore.generateRegistrationSummaryPdf(
                          row.original.tumorRegistrationId
                        )
                      }
                    />
                  </TableContainer>
                </>
              ) : (
                <TumorSampleRegistrationProcess
                  key={
                    dataStore.keyRegisterForm.reset
                      ? dataStore.keyRegisterForm.newValue
                      : dataStore.keyRegisterForm.value
                  }
                  patientRegistrationId={patientRegistrationProcess.id}
                  dataStore={dataStore}
                />
              )}
            </Box>
          </Flex>
        </Flex>
        <AlertConfirm
          title={t('modals.delete.title')}
          message={t('modals.delete.message')}
          isOpen={isOpen}
          onClose={onCloseAlert}
          onAccept={deleteTumorRegistration}
          leastDestructiveRef={cancelRef}
        />
      </>
    );
  }
);

export default TumorSampleRegistrationPage;
