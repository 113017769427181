import {useDisclosure} from '@chakra-ui/react';
import {ReferenceRegistrationGenderEnum, ReferenceRegistrationSampleTypeEnum,} from '@hmf/portal-api-client';
import {FormikProps} from 'formik';
import {useEffect, useRef, useState} from 'react';
import {Data} from '../../../stores/data';
import {ReferenceSampleFormDto} from './useReferenceSampleForm';
import {UNKNOWN_CONTRACT_OPTION_VALUE} from "../components/ReferenceSampleForm";

export const useReferenceSampleRegistration = (dataStore: Data) => {
  const [messageSaving, setMessageSaving] = useState<string>('');
  const [saving, setSaving] = useState<boolean>(false);

  const [isDisabledSubmit, setIsDisabledSubmit] = useState(false);

  const referenceSampleFormRef = useRef<FormikProps<ReferenceSampleFormDto>>(null);

  useEffect(() => {
    dataStore.fetchHospitalOptions();
  }, [dataStore]);

  const onSaving = async () => {
    setMessageSaving('modals.saving.saving');
    onOpenModalSaving();
    setSaving(true);
    const values = referenceSampleFormRef.current?.values;
    const isDiagnostic = dataStore.isDiagnostic(values?.contractCode)
    await dataStore.createReferenceRegistration({
      hospitalId: Number(values?.hospitalId),
      patientId: isDiagnostic ? values?.patientId! : `${values?.studyIdPrefix}${values?.patientId}`,
      gender: (values?.gender! as ReferenceRegistrationGenderEnum) || undefined,
      currentSurname: values?.currentSurname || undefined,
      dateOfBirth: values?.dateOfBirth || undefined,
      barcode: values?.barcode!,
      concentration: values?.concentration ? Number(values?.concentration) : undefined,
      sampleType: values?.sampleType! as ReferenceRegistrationSampleTypeEnum,
      contractCode: values?.contractCode === UNKNOWN_CONTRACT_OPTION_VALUE ? undefined : values?.contractCode,
      isDiagnosticCohort: isDiagnostic
    });
    setSaving(false);
    onCLoseModalSaving();
  };

  const {
    isOpen: isOpenModalSaving,
    onOpen: onOpenModalSaving,
    onClose: onCLoseModalSaving,
  } = useDisclosure();
  const {
    isOpen: isOpenModalFinish,
    onOpen: onOpenModalFinish,
    onClose: onCLoseModalFinish,
  } = useDisclosure();

  const {
    isOpen: isOpenCancel,
    onOpen: onOpenCancel,
    onClose: onCloseCancel,
  } = useDisclosure();
  const cancelRef = useRef();

  const onCancelProcess = () => {
    dataStore.resetReferenceRegistrationOptions();
    dataStore.fetchReferenceRegistrations();
    dataStore.showReferenceSampleRegistrationTable(true);
    dataStore.closeAccordionWizard(false);
    dataStore.clearReferenceSampleRegistrationProcess();
    dataStore.setResetForm(false);
  };

  const onNewRegister = () => {
    dataStore.resetFormKey();
    dataStore.closeAccordionWizard(false);
    onCLoseModalFinish();
  };

  const onFinishButton = async () => {
    await onSaving();
    onOpenModalFinish();
  };

  return {
    referenceSampleFormRef: referenceSampleFormRef,
    isDisabledSubmit,
    setIsDisabledSubmit,
    savingModal: {
      saving,
      messageSaving,
      isOpenModalSaving,
    },
    finish: {
      isOpenModalFinish,
      onCLoseModalFinish,
      onNewRegister,
      onFinishButton,
    },
    cancel: {
      cancelRef,
      isOpenCancel,
      onOpenCancel,
      onCloseCancel,
      onCancelProcess,
    },
  };
};
