import {AddIcon, LockIcon, MinusIcon, UnlockIcon} from '@chakra-ui/icons';
import {AccordionButton, AccordionItem, AccordionPanel, Box, Flex, FormLabel, Input,} from '@chakra-ui/react';
import {BiopsySiteInformationLateralisationEnum} from '@hmf/portal-api-client';
import {Form, Formik} from 'formik';
import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {RadioGroupChakraFormik} from '../../Formik';
import {BiopsySiteInfo, useBiopsySiteInfo} from '../hooks/useBiopsySiteInfo';
import {StepTumorSampleRegistrationProps} from '../interfaces/Steps';

export const StepBiopsySiteInfo: React.FC<
  StepTumorSampleRegistrationProps<BiopsySiteInfo>
> = observer(
  ({
     dataStore,
     edit,
     onValidate,
     active,
     innerRef,
   }: StepTumorSampleRegistrationProps<BiopsySiteInfo>) => {
    const [t] = useTranslation();
    const {
      search,
      biopsyLocations,
      biopsySubLocations,
      onChangeSearch,
      onChangeBiopsyLocation,
      onChangeBiopsySubLocation,
      initialValuesForm,
      onSubmit,
      validationSchema
    } = useBiopsySiteInfo(dataStore);

    const [wasHovered, setWasHovered] = useState<boolean>(false);

    return (
      <AccordionItem pb={4}>
        {({isExpanded}) => (
          <>
            <h2>
              <AccordionButton
                onMouseLeave={() => setWasHovered(false)}
                onMouseEnter={() => setWasHovered(true)}
              >
                <Box flex="1" marginLeft={!active ? '2' : '6'} textAlign="left">
                  {edit && !isExpanded && !wasHovered ? (
                    <LockIcon fontSize="12px" marginRight="5"/>
                  ) : (
                    <UnlockIcon fontSize="12px" marginRight="5"/>
                  )}
                  {t('tumorRegistrationProcess.biopsySite.title')}
                </Box>
                {isExpanded ? (
                  <MinusIcon fontSize="12px"/>
                ) : (
                  <AddIcon fontSize="12px"/>
                )}
              </AccordionButton>
            </h2>
            <AccordionPanel paddingX={10} background="#F6F7FC" pb={4}>
              <Formik
                innerRef={innerRef}
                initialValues={initialValuesForm}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnChange={true}
              >
                {({values, isValid, dirty, setFieldValue, handleChange}) => {
                  onValidate(edit ? isValid : isValid && dirty);
                  return (
                    <Form>
                      <Box w="40%" mb="5">
                        <FormLabel fontSize="sm" htmlFor="Search">
                          {t(
                            'tumorRegistrationProcess.biopsySite.labels.search'
                          )}
                        </FormLabel>
                        <Input
                          value={search}
                          onChange={onChangeSearch}
                          variant="outline"
                          borderColor="gray"
                          focusBorderColor="gray"
                        />
                      </Box>

                      <Flex align="center" mb="5">
                        <RadioGroupChakraFormik
                          key="biopsyLocation"
                          label={t(
                            'tumorRegistrationProcess.biopsySite.labels.biopsyLocation'
                          )}
                          name="biopsyLocation"
                          onChange={(e) => {
                            setFieldValue('biopsySubLocation', '');
                            setFieldValue('lateralisation', '');
                            setFieldValue('isPrimaryTumor', '');
                            handleChange(e);
                            onChangeBiopsyLocation(e);
                          }}
                          search={search}
                          options={biopsyLocations
                            .map((biopsy) => ({
                              label: t(`biopsySites.${biopsy.name}`, {
                                defaultValue: biopsy.name,
                              }),
                              value: biopsy.id.toString(),
                            }))
                            .sort((a, b) => a.label.localeCompare(b.label))}
                          isRequired={true}
                          grid={true}
                        />
                      </Flex>

                      {values.biopsyLocation && (
                        <Flex align="center" mb="5">
                          <RadioGroupChakraFormik
                            mt="5"
                            label={t(
                              'tumorRegistrationProcess.biopsySite.labels.biopsySubLocation'
                            )}
                            name="biopsySubLocation"
                            search={search}
                            onChange={(e) => {
                              setFieldValue('lateralisation', '');
                              setFieldValue('isPrimaryTumor', '');
                              handleChange(e);
                              onChangeBiopsySubLocation(e);
                            }}
                            options={biopsySubLocations
                              .map((biopsySubLocation) => ({
                                label: t(
                                  `biopsySites.${biopsySubLocation.name}`,
                                  {
                                    defaultValue: biopsySubLocation.name,
                                  }
                                ),
                                value: biopsySubLocation.id.toString(),
                              }))
                              .sort((a, b) => a.label.localeCompare(b.label))}
                            isRequired={true}
                            grid={true}
                          />
                        </Flex>
                      )}

                      {values.biopsySubLocation && (
                        <>
                          {biopsySubLocations.find(
                            (biopsySubLocation) =>
                              biopsySubLocation.id.toString() ===
                              values?.biopsySubLocation
                          )?.lateralisationRequired && (
                            <RadioGroupChakraFormik
                              label={t(
                                'tumorRegistrationProcess.biopsySite.labels.lateralization'
                              )}
                              name="lateralisation"
                              mb="5"
                              options={Object.entries(
                                BiopsySiteInformationLateralisationEnum
                              ).map(([key, value]) => ({
                                label: t(`tumorRegistrationProcess.biopsySite.lateralisationOptions.${value.toLowerCase()}`),
                                value,
                              }))}
                            />
                          )}

                          <RadioGroupChakraFormik
                            label={t(
                              'tumorRegistrationProcess.biopsySite.labels.isPrimaryTumor'
                            )}
                            name="isPrimaryTumor"
                            mb="5"
                            options={[
                              {label: t('general.yes'), value: 'true'},
                              {label: t('general.no'), value: 'false'},
                              {label: t('general.unknown'), value: 'unknown'},
                            ]}
                            isRequired={false}
                          />
                        </>
                      )}
                    </Form>
                  );
                }}
              </Formik>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    );
  }
);
