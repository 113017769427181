import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import {Field, FieldProps, useField} from 'formik';
import {FC} from 'react';

interface props {
  label: string;
  name: string;
  search?: string;
  options: optionValue[];
  isRequired?: boolean;
  grid?: boolean;

  [x: string]: any;
}

export interface optionValue {
  label: string;
  value: string;
}

export const RadioGroupChakraFormik: FC<props> =
  ({
     label,
     search = '',
     options = [],
     isRequired = false,
     grid = false,
     ...props
   }: props) => {
    const [field, meta] = useField(props);

    const isDisabled = (search: string, value: string): boolean => {
      let isDisabled = false;
      if (search && search === '') return isDisabled;
      isDisabled = !value.toLowerCase().includes(search?.toLowerCase());
      return isDisabled;
    };

    return (
      <Field name={field.name}>
        {({field}: FieldProps) => (
          <FormControl
            {...props}
            id={field.name}
            isInvalid={meta.error?.length! > 0 && meta.touched}
            isRequired={isRequired}
          >
            <FormLabel
              fontWeight="medium"
              fontSize="sm"
              htmlFor={field.name}
              requiredIndicator={
                <Text color="red" display="inline">
                  *
                </Text>
              }
            >
              {label}
            </FormLabel>
            <RadioGroup isDisabled={props.isDisabled} {...field} id={field.name}>
              {grid ? (
                <Grid
                  border="1px solid grey"
                  padding={5}
                  gridTemplateRows="repeat(13, auto)"
                  gridAutoFlow="column"
                  gridAutoColumns="auto-fill"

                >
                  {options.map((option, index) => (
                    <Radio
                      key={index}
                      colorScheme="gray"
                      _focusVisible={{shadow: 'outline'}}
                      _focus={{shadow: 'none'}}
                      isDisabled={
                        isDisabled(search, option.label) || props.isDisabled
                      }
                      _disabled={{
                        colorScheme: 'none',
                        borderColor: 'none',
                      }}
                      {...field}
                      value={option.value}
                    >
                      <Box
                        color="black"
                        fontWeight={
                          !(
                            isDisabled(search, option.label) || props.isDisabled
                          ) && search !== ''
                            ? 'bold'
                            : 'medium'
                        }
                        fontSize="small"
                      >
                        {option.label}
                      </Box>
                    </Radio>
                  ))}
                </Grid>
              ) : (
                <Stack direction="row">
                  {options.map((option, index) => (
                    <Radio
                      key={index}
                      colorScheme="gray"
                      _focusVisible={{shadow: 'outline'}}
                      _focus={{shadow: 'none'}}
                      isDisabled={
                        isDisabled(search, option.label) || props.isDisabled
                      }
                      _disabled={{
                        colorScheme: 'none',
                        borderColor: 'none',
                      }}
                      {...field}
                      value={option.value}
                    >
                      <Box
                        color="black"
                        fontSize="sm"
                        fontWeight={
                          !(
                            isDisabled(search, option.label) || props.isDisabled
                          ) && search !== ''
                            ? 'bold'
                            : 'normal'
                        }
                      >
                        {option.label}
                      </Box>
                    </Radio>
                  ))}
                </Stack>
              )}
            </RadioGroup>
            <FormErrorMessage>{meta.error}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
    );
  };
