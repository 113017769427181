import {Box} from '@chakra-ui/react';
import {TriangleDownIcon, TriangleUpIcon} from '@chakra-ui/icons';

const SortingIndicators = ({isSorted, isSortedAsc, isSortedDesc}) => {
  return (
    <Box
      display="inline-flex"
      flexDirection="column"
      h="1.4px"
      position="relative"
      top="-4px"
      left="10px"
    >
      <TriangleUpIcon
        w={4}
        color={isSorted && isSortedAsc ? 'white' : 'gray.500'}
      />
      <TriangleDownIcon
        w={4}
        color={isSorted && isSortedDesc ? 'white' : 'gray.500'}
      />
    </Box>
  );
};

export default SortingIndicators;
