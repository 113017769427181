import {CheckIcon} from '@chakra-ui/icons';
import {
  Button,
  Center,
  Container,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {MdDesktopWindows} from 'react-icons/md';

interface ModalRegistrationProps {
  isOpen: boolean;
  onClose(): void;
  onRegister(): void;
  onBack(): void;
}

const ModalRegistration =
  ({
     isOpen,
     onClose,
     onRegister,
     onBack,
   }: ModalRegistrationProps) => {
    const [t] = useTranslation();
    return (
      <Modal
        closeOnOverlayClick={false}
        size="xl"
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay/>
        <ModalContent>
          <ModalBody pt={6} pb={10}>
            <Flex
              direction={'column'}
              position="relative"
              justifyContent="center"
            >
              <CheckIcon
                position="absolute"
                top="0"
                padding="2"
                boxSize={10}
                right="190"
                borderRadius="100"
                background="#8ec5f2"
                color="white"
              />
              <Container
                w={150}
                alignItems="center"
                py={9}
                h={150}
                borderRadius="100"
                background="#304B99"
              >
                <Center>
                  <MdDesktopWindows size={80} color="white"/>
                </Center>
              </Container>
            </Flex>
            <Flex direction={'column'} justifyContent="center">
              <Text
                letterSpacing={0.5}
                color="#000000"
                align={'center'}
                fontSize="18"
                mt={5}
              >
                {t('modals.finish.title')}
              </Text>
              <Text
                letterSpacing={0.5}
                color="#000000"
                align={'center'}
                fontSize="18"
                mt={5}
              >
                {t('modals.finish.message')}
              </Text>
            </Flex>
          </ModalBody>
          <ModalFooter justifyContent={'center'}>
            <Button
              bg={'white'}
              variant="outline"
              color="#304B99"
              boxShadow="md"
              borderColor="#304B99"
              fontSize={14}
              mr={3}
              onClick={onRegister}
            >
              {t('modals.finish.register')}
            </Button>
            <Button
              color="white"
              bg="#304B99"
              boxShadow="md"
              variant="outline"
              borderColor="gray.300"
              fontSize={14}
              h={10}
              px={5}
              onClick={onBack}
            >
              {t('modals.finish.back')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

export default ModalRegistration;
