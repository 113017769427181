import {Box, CircularProgress, CircularProgressLabel, Flex, Progress, Spacer,} from '@chakra-ui/react';

const GeneralStepper = ({ProgressValue = 0}) => {
  return (
    <Box marginX={5} marginY={5} w="20%">
      <Flex flex={1} alignItems="center">
        <Box w="80%">
          <Progress value={ProgressValue} size="xs" color={'#304B99'}/>
        </Box>
        <Spacer></Spacer>
        <CircularProgress value={ProgressValue} color={'#304B99'}>
          <CircularProgressLabel>{ProgressValue}%</CircularProgressLabel>
        </CircularProgress>
      </Flex>
    </Box>
  );
};

export default GeneralStepper;
