import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import { FC, RefObject } from 'react';
import { useTranslation } from 'react-i18next';

export type AlertConfirmProps = {
  isOpen: boolean;
  title: string;
  message: string;
  buttonCancel?: string;
  actionButton?: string;
  onClose(): void;
  onAccept(): void;
  leastDestructiveRef: RefObject<any>;
};

export const AlertConfirm: FC<AlertConfirmProps> = ({
  title,
  message,
  leastDestructiveRef,
  buttonCancel,
  actionButton,
  isOpen,
  onClose,
  onAccept,
  ...props
}: AlertConfirmProps) => {
  const [t] = useTranslation();
  return (
    <AlertDialog
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={onClose}
      leastDestructiveRef={leastDestructiveRef}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>
          <AlertDialogBody>{message}</AlertDialogBody>
          <AlertDialogFooter>
            <Button
              color="#304B99"
              bg={'white'}
              boxShadow="md"
              variant="outline"
              borderColor="#304B99"
              fontSize={14}
              h={10}
              px={5}
              ref={leastDestructiveRef}
              onClick={onClose}
            >
              {buttonCancel || t('modals.delete.cancel')}
            </Button>
            <Button
              color="white"
              bg="#304B99"
              boxShadow="md"
              variant="solid"
              borderColor="gray.300"
              fontSize={14}
              h={10}
              px={5}
              onClick={onAccept}
              ml={3}
            >
              {actionButton || t('modals.delete.action')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
