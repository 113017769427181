import {FormControl, FormErrorMessage, FormLabel, Text,} from '@chakra-ui/react';
import {GroupBase, Select} from 'chakra-react-select';
import {useField} from 'formik';
import {FC} from 'react';

interface props {
  name: string;
  label?: string;
  placeholder?: string;
  options: OptionList;
  isRequired?: boolean;

  [x: string]: any;
}

export interface Option {
  value: string;
  label: string;
}

type OptionList = Array<Option>;

export const SelectFormik: FC<props> =
  ({
     id,
     label,
     placeholder = 'search',
     options = [],
     onChange,
     isRequired = false,
     ...props
   }: props) => {
    const [field, meta, helpers] = useField(props);
    const {setValue} = helpers;

    const defaultValue = (options: OptionList, value: string) => {
      return options.find((option) => option.value === value) || null;
    };

    return (
      <FormControl
        isInvalid={meta.error?.length! > 0 && meta.touched}
        isRequired={isRequired}
        isDisabled={props.isDisabled}
      >
        <FormLabel
          fontSize="sm"
          htmlFor={field.name}
          requiredIndicator={
            <Text color="red" display="inline">
              *
            </Text>
          }
        >
          {label}
        </FormLabel>
        <Select<Option, false, GroupBase<Option>>
          name={field.name}
          className="chakra-react-select"
          classNamePrefix="chakra-react-select"
          options={options}
          placeholder={placeholder}
          value={defaultValue(options, field.value)}
          selectedOptionStyle="check"
          isDisabled={props.isDisabled}
          onChange={(e) => {
            if (onChange) {
              onChange(e);
            }
            setValue(e?.value);
          }}
          menuPortalTarget={document.body}
          styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
          chakraStyles={{
            dropdownIndicator: (provided) => ({
              ...provided,
              bg: 'transparent',
              p: 0,
              w: 6,
              mx: 2,
              cursor: 'inherit',
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
          }}
        />
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      </FormControl>
    );
  };
