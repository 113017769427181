import {Box, Button, Flex, Spacer, TableContainer, Text, useDisclosure,} from '@chakra-ui/react';
import WATERMark from '../assets/watermark.png';
import {Structures} from './ColumnsTable';
import TableComponent from './Table';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {useEffect, useRef, useState} from 'react';
import {Data} from '../stores/data';
import {ReferenceSampleRegistration} from './ReferenceSampleRegistration/ReferenceSampleRegistration';
import {AlertConfirm} from './Alerts/AlertConfirm';

const ReferenceSampleRegistrationPage = observer(
  ({height, dataStore}: { height: any; dataStore: Data }) => {
    const [t] = useTranslation();
    const [referenceRegistrationId, setReferenceRegistrationId] = useState();
    const {isOpen, onOpen, onClose} = useDisclosure();
    const cancelRef = useRef();

    useEffect(() => {
      dataStore.fetchReferenceRegistrations();
    }, [dataStore]);

    const onCloseAlert = () => {
      onClose();
      setReferenceRegistrationId(undefined);
    };

    const deleteReferenceRegistration = async () => {
      if (referenceRegistrationId) {
        await dataStore.deleteReferenceRegistration(referenceRegistrationId);
        await dataStore.fetchReferenceRegistrations();
        onClose();
        setReferenceRegistrationId(undefined);
      }
    };

    return (
      <>
        <Flex
          direction="row"
          height={height}
          backgroundImage={WATERMark}
          backgroundRepeat="no-repeat"
          backgroundPosition="90%"
          backgroundAttachment="inherit"
          backgroundSize="65%"
          style={{backgroundPositionY: 'bottom'}}
        >
          <Flex direction="column" flex={1} bgColor="#D4D9E7">
            <Box
              marginTop="15"
              marginRight="20"
              marginLeft="20"
              marginBottom="25"
              border="1px"
              borderColor="#A0AEBE"
              shadow="lg"
              backgroundColor="white"
            >
              {dataStore.isReferenceSampleRegistrationTable ? (
                <>
                  <Flex mt={15} mx={5}>
                    <Text
                      textTransform="uppercase"
                      letterSpacing={0.5}
                      color="#5E5873"
                      fontSize="18"
                    >
                      {t('table.actions.referenceSamples.title')}
                    </Text>
                    <Spacer></Spacer>
                    <Button
                      color="black"
                      bg={'white'}
                      boxShadow="md"
                      variant="outline"
                      borderColor="gray.300"
                      fontSize={14}
                      h={10}
                      px={5}
                      onClick={() => dataStore.showReferenceSampleRegistrationTable(false)}
                    >
                      {'+ ' + t('table.actions.referenceSamples.newRegistration')}
                    </Button>
                  </Flex>
                  <TableContainer
                    marginTop="15"
                    marginRight="18"
                    marginLeft="18"
                    marginBottom="25"
                  >
                    <TableComponent
                      columns={{
                        structure: Structures.REFERENCE_SAMPLES,
                        texts: 'table.referenceSamples',
                      }}
                      includeCheckboxes={false}
                      sortBy={[{desc: true, id: 'startDate'}]}
                      data={dataStore.referenceSamples}
                      hoverRow={true}
                      includeActionButton={true}
                      actionButtonType={'DELETE'}
                      onClickDelete={(row) => {
                        setReferenceRegistrationId(row.original.referenceRegistrationId);
                        onOpen();
                      }}
                    />
                  </TableContainer>
                </>
              ) : (
                <ReferenceSampleRegistration
                  key={
                    dataStore.keyRegisterForm.reset
                      ? dataStore.keyRegisterForm.newValue
                      : dataStore.keyRegisterForm.value
                  }
                  dataStore={dataStore}
                />
              )}
            </Box>
          </Flex>
        </Flex>
        <AlertConfirm
          title={t('modals.delete.titleReferenceSamples')}
          message={t('modals.delete.message')}
          isOpen={isOpen}
          onClose={onCloseAlert}
          onAccept={deleteReferenceRegistration}
          leastDestructiveRef={cancelRef}
        />
      </>
    );
  }
);

export default ReferenceSampleRegistrationPage;
