import {makeAutoObservable, runInAction} from 'mobx';
import {initializeApp} from 'firebase/app';
import {
  Auth as AuthFirebase,
  getAdditionalUserInfo,
  getAuth,
  getRedirectResult,
  SAMLAuthProvider,
  signInWithRedirect,
  UserCredential,
} from 'firebase/auth';

export const DISPLAY_NAME_ATTRIBUTE = 'urn:mace:dir:attribute-def:displayName';

export function getAttribute(user: UserCredential, name: string): string {
  const additionalUserInfo = getAdditionalUserInfo(user);
  if (additionalUserInfo) {
    const profile = additionalUserInfo.profile;
    if (profile) {
      const email = profile[name] as string;
      if (email) {
        return email;
      }
    }
  }
  return '';
}

export class Auth {
  user: UserCredential | null = null;
  auth: AuthFirebase | undefined;

  private readonly config = {
    apiKey: process.env?.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env?.REACT_APP_FIREBASE_AUTH_DOMAIN,
    providerId: process.env?.REACT_APP_FIREBASE_PROVIDER_ID,
  };

  constructor() {
    makeAutoObservable(this);

    initializeApp(this.config);

    this.auth = getAuth();

    this.ensureAuthentication();
  }

  async ensureAuthentication() {
    getRedirectResult(this.auth as AuthFirebase).then((result) => {
      if (!result) {
        const provider = new SAMLAuthProvider(this.config.providerId!);
        signInWithRedirect(this.auth as AuthFirebase, provider).catch(
          (error) => {
            console.log(`Error: ${error}`);
          }
        );
      } else {
        runInAction(() => {
          this.user = result;
        });
      }
    });
  }

  async logOut() {
    try {
      await this.auth?.signOut();
      this.user = null;
      window.location.replace('https://engine.surfconext.nl/logout');
    } catch (error) {
      console.log(error);
    }
  }

  async refreshUser() {
    try {
      await this.auth?.currentUser?.getIdToken(true);
    } catch (error) {
      console.log(error);
    }
  }
}
