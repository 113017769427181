import {Box, Flex, FormControl, FormErrorMessage, FormLabel, Input, Text,} from '@chakra-ui/react';
import {useField} from 'formik';
import {FC} from 'react';

interface props {
  name: string;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  variant?: string;
  id?: string;

  [x: string]: any;
}

export const PrefixedInputFormik: FC<props> =
  ({
     label,
     prefix,
     variant = 'flushed',
     isRequired = false,
     ...props
   }: props) => {
    const [field, meta] = useField(props);
    return (
      <FormControl
        isInvalid={meta.error?.length! > 0 && meta.touched}
        isRequired={isRequired}
      >
        <FormLabel
          fontSize="sm"
          opacity={props.isDisabled ? 0.4 : 1}
          requiredIndicator={
            <Text color="red" display="inline">
              *
            </Text>
          }
        >
          {label}
        </FormLabel>
        <Flex align="center">
          {prefix && (
            <Box pr={0}>
              {prefix}
            </Box>
          )}
          <Input {...props} {...field} variant={variant} fontSize="m" flex="1"/>
        </Flex>
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      </FormControl>
    );
  };
