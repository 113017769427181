import {
  AnalysisResult,
  AnalysisTypeOption,
  ApiResponse,
  BiopsyLocation,
  BiopsySiteInformation,
  BiopsySubLocation,
  Configuration,
  ConsentInformation,
  ConsentVersionOption,
  ContractOption,
  DefaultApi,
  DiagnosticPatientInformation,
  DownloadCategoriesEnum,
  DownloadTypeEnum,
  HospitalOption,
  ReferenceRegistration,
  ReferenceRegistrationGenderEnum,
  ReferenceRegistrationOverview,
  ReferenceRegistrationSampleTypeEnum,
  ReportComponentInfo,
  RolesRolesEnum,
  SampleInformation,
  SampleTypeOption,
  TumorExtra,
  TumorLocation,
  TumorRegistration,
  TumorRegistrationOverview,
  TumorType,
  TumorTypeInformation,
} from '@hmf/portal-api-client';
import {makeAutoObservable, runInAction} from 'mobx';
import {parseDate} from '../utils';
import {UNKNOWN_CONTRACT_OPTION_VALUE} from "../components/ReferenceSampleRegistration/components/ReferenceSampleForm";

type ProcessStep = {
  active: boolean;
  shouldRender: boolean;
  edit: boolean;
};

export type TumorRegistrationStepName =
  | 'REGISTRATION_SETUP'
  | 'DIAGNOSTIC_PATIENT_INFORMATION'
  | 'PATIENT_CONSENT'
  | 'TUMOR_TYPE'
  | 'BIOPSY_SITE_INFO'
  | 'SAMPLE_DETAILS';

type TumorRegistrationStep = ProcessStep & {
  step: TumorRegistrationStepName
};

export const LEGAL_CHARACTER_REGEX = /^[0-9a-zA-Z-]*$/;

export type TumorRegistrationProcess = {
  tumorRegistration?: TumorRegistration;
  diagnosticPatientInfo?: DiagnosticPatientInformation;
  consentInfo?: ConsentInformation;
  tumorTypeInfo?: TumorTypeInformation;
  biopsySiteInfo?: BiopsySiteInformation;
  sampleInfo?: SampleInformation;
  steps: TumorRegistrationStep[];
  processPercentage: number;
};

export type ReferenceSampleRegistrationStepName =
  | 'HOSPITAL_INFORMATION'
  | 'PATIENT_INFORMATION'
  | 'SAMPLE_DETAILS';

type ReferenceSampleRegistrationStep = ProcessStep & {
  step: ReferenceSampleRegistrationStepName;
};

export type ReferenceSampleRegistrationProcess = {
  hospitalInfo?: {
    hospitalId: number;
    analysisTypeId: number;
    contractCode: string;
    patientId: string;
  };
  patientInfo?: {
    birthSurname?: string;
    dateOfBirth?: Date;
    gender?: ReferenceRegistrationGenderEnum;
    postalCode?: string;
  };
  sampleInfo?: {
    barcode: string;
    sampleType: ReferenceRegistrationSampleTypeEnum;
    concentration?: number;
  };
  isPatientInfoCompleted?: boolean;
  steps: ReferenceSampleRegistrationStep[];
};

export type ParsedAnalysis = Omit<AnalysisResult, 'reportedDate'> & {
  reportedDate: string;
};

export type ParsedTumorRegistration = Omit<
  TumorRegistrationOverview,
  'startDate'
> & {
  startDate: string;
};

export type TumorLocationSearch = TumorLocation & {
  matches: boolean
};

export type TumorTypesSearch = TumorType & {
  matches: boolean
};

export function isSingleAnalysisType(dataStore: Data) {
  return dataStore.analysisTypeOptions.length === 1;
}

export const checkSpecialCharacters = (e) => {
    if (!/[0-9a-zA-Z-]/.test(e.key)) {
      e.preventDefault();
    }
};

export function isRuoFolder(folder: string | undefined): boolean {
  return folder === "RUO"
}

export function isRuoGermlineFolder(folder: string): boolean {
  return folder === "RUO_germline"
}


export class Data {
  private config: Configuration;
  private api: DefaultApi;

  analyses: ParsedAnalysis[] = [];
  selectedAnalysis: ParsedAnalysis | null = null;
  reportComponents: ReportComponentInfo[] = [];
  componentPreview: string | null = null;
  referenceSamples: ReferenceRegistrationOverview[] = [];
  tumorRegistrations: ParsedTumorRegistration[] = [];

  tumorRegistrationProcess: TumorRegistrationProcess = {
    steps: [],
    processPercentage: 0,
  };
  referenceSampleRegistrationProcess: ReferenceSampleRegistrationProcess = {
    steps: [],
  };

  hospitalOptions: HospitalOption[] = [];
  contractOptions: ContractOption[] = [];
  consentVersionOptions: ConsentVersionOption[] = [];
  sampleTypeOptions: SampleTypeOption[] = [];
  analysisTypeOptions: AnalysisTypeOption[] = [];
  tumorLocations: TumorLocationSearch[] = [];
  tumorTypes: TumorTypesSearch[] = [];
  tumorExtra: TumorExtra[] = [];
  biopsyLocations: BiopsyLocation[] = [];
  biopsySubLocations: BiopsySubLocation[] = [];
  isDownloading: boolean = false;
  statusError: number | null = null;

  isReferenceSampleRegistrationTable: boolean = true;
  isTumorRegistrationTable: boolean = true;
  isTheAccordionClosed: boolean = false;
  keyRegisterForm = {
    reset: false,
    value: 'key_form',
    newValue: 'key_form',
  };

  identityRoles: Set<RolesRolesEnum> = new Set();

  constructor(token: string, public onUnauthorized: () => Promise<string>) {
    makeAutoObservable(this);

    this.config = new Configuration({
      accessToken: token,
      basePath: process.env.REACT_APP_PORTAL_API_URL,
    });
    this.api = new DefaultApi(this.config);
    this.getIdentity();
  }

  async updateToken() {
    const newToken = await this.onUnauthorized();
    this.config = new Configuration({
      accessToken: newToken,
      basePath: process.env.REACT_APP_PORTAL_API_URL,
    });
    this.api = new DefaultApi(this.config);
  }

  setSelectedAnalysis(analysisId: number) {
    this.selectedAnalysis =
      this.analyses.find((analysis) => analysis.reportId === analysisId) ||
      null;
  }

  clearTumorRegistrationProcess(onlyStepsAndPercentage: boolean = false) {
    runInAction(() => {
      if (onlyStepsAndPercentage) {
        this.tumorRegistrationProcess.steps = [];
        this.tumorRegistrationProcess.processPercentage = 0;
      } else {
        this.tumorRegistrationProcess = {
          steps: [],
          processPercentage: 0,
        };
      }
    });
  }

  showTumorRegistrationTable(value: boolean) {
    this.isTumorRegistrationTable = value;
  }

  showReferenceSampleRegistrationTable(value: boolean) {
    this.isReferenceSampleRegistrationTable = value;
  }

  resetFormKey() {
    this.keyRegisterForm = {
      ...this.keyRegisterForm,
      reset: true,
      newValue: Math.random().toString(),
    };
  }

  setResetForm(value: boolean) {
    this.keyRegisterForm = {
      ...this.keyRegisterForm,
      reset: value,
    };
  }

  closeAccordionWizard(value: boolean) {
    this.isTheAccordionClosed = value;
  }

  forceTumorRegistrationStep(stepIndex: number) {
    const steps = this.tumorRegistrationProcess.steps;
    this.tumorRegistrationProcess.steps = steps.map((step, index) => {
      step.active = index === stepIndex;
      step.edit = index === stepIndex;
      return step;
    });
  }

  isDiagnostic(contractCodeToTest: string | undefined): boolean {
    return !!(contractCodeToTest === UNKNOWN_CONTRACT_OPTION_VALUE
      || this.contractOptions.find(({contractCode}) => contractCode === contractCodeToTest
      )?.samplesAreDiagnostic)
  }

  async getIdentity(): Promise<void> {
    try {
      const {roles} = await this.api.getRoles();
      runInAction(() => {
        roles.forEach((role) => this.identityRoles.add(role));
      });
    } catch (error) {
      console.error(error);
    }
  }

  async handleTumorRegistrationProcess(tumorRegistrationId?: number): Promise<void> {
    if (tumorRegistrationId) {
      await this.getTumorRegistration(tumorRegistrationId);
      await this.getDiagnosticPatientInformationByTumorRegistration(tumorRegistrationId);
      await this.getConsentInformationByTumorRegistration(tumorRegistrationId);
      await this.getTumorTypeInformationByTumorRegistration(tumorRegistrationId);
      await this.getBiopsySiteInformationByTumorRegistration(tumorRegistrationId);
      await this.getSampleInformationByTumorRegistration(tumorRegistrationId);
    }

    let {
      tumorRegistration,
      diagnosticPatientInfo,
      consentInfo,
      tumorTypeInfo,
      sampleInfo,
      biopsySiteInfo,
    } = this.tumorRegistrationProcess;

    let isAnStepActive = false;

    this.clearTumorRegistrationProcess(true);

    // * 1st step validation.
    // * If we don't receive a tumorRegistrationId or the tumor registration wasn't found
    // * then we're safe to assume it should start from the first step.
    this.tumorRegistrationProcess.steps.push({
      active: !tumorRegistrationId || !tumorRegistration,
      shouldRender: !isAnStepActive,
      edit: false,
      step: 'REGISTRATION_SETUP',
    });
    if (!tumorRegistrationId || !tumorRegistration) {
      isAnStepActive = true;
    }

    // * 2nd step validation.
    if (tumorRegistration?.isDiagnosticCohort) {
      // * Is there a current diagnostic patient information?
      this.tumorRegistrationProcess.steps.push({
        active: !diagnosticPatientInfo && !isAnStepActive,
        shouldRender: !isAnStepActive,
        edit: false,
        step: 'DIAGNOSTIC_PATIENT_INFORMATION',
      });

      if (!diagnosticPatientInfo && !isAnStepActive) {
        isAnStepActive = true;
      }
    }

    // * 3rd step validation.
    if ((tumorRegistration?.isDiagnosticCohort && tumorRegistration.hasDatabaseContract) || !tumorRegistration?.isDiagnosticCohort) {
      this.tumorRegistrationProcess.steps.push({
        active: !consentInfo && !isAnStepActive,
        shouldRender: !isAnStepActive,
        edit: false,
        step: 'PATIENT_CONSENT',
      });

      if (!consentInfo && !isAnStepActive) {
        isAnStepActive = true;
      }
    }

    // * 4th validation - WIP
    this.tumorRegistrationProcess.steps.push({
      active: !tumorTypeInfo && !isAnStepActive,
      shouldRender: !isAnStepActive,
      edit: false,
      step: 'TUMOR_TYPE',
    });

    if (!tumorTypeInfo && !isAnStepActive) {
      isAnStepActive = true;
    }

    // * 5th validation - WIP
    this.tumorRegistrationProcess.steps.push({
      active: !biopsySiteInfo && !isAnStepActive,
      shouldRender: !isAnStepActive,
      edit: false,
      step: 'BIOPSY_SITE_INFO',
    });

    if (!biopsySiteInfo && !isAnStepActive) {
      isAnStepActive = true;
    }

    // * 6th validation - WIP
    this.tumorRegistrationProcess.steps.push({
      active: !sampleInfo && !isAnStepActive,
      shouldRender: !isAnStepActive,
      edit: false,
      step: 'SAMPLE_DETAILS',
    });

    const currentActiveStepIndex = this.tumorRegistrationProcess.steps.findIndex((step) => step.active);
    const totalSteps = this.tumorRegistrationProcess.steps.length;
    runInAction(() => {
      this.tumorRegistrationProcess.processPercentage = +(
        ((currentActiveStepIndex >= 0 ? currentActiveStepIndex : totalSteps) / totalSteps) * 100)
        .toFixed(0);
    });
  }

  clearReferenceSampleRegistrationProcess(onlyStepsAndPercentage: boolean = false) {
    if (onlyStepsAndPercentage) {
      this.referenceSampleRegistrationProcess.steps = [];
    } else {
      this.referenceSampleRegistrationProcess = {
        steps: [],
      };
    }
  }

  async fetchAnalyses() {
    try {
      const analyses = await this.api.listAnalyses();
      runInAction(() => {
        this.analyses = analyses.map((a) => ({...a, reportedDate: parseDate(a.reportedDate)}));
      });
    } catch (error) {
      await this.handleError(error, true);
    }
  }

  async fetchReportComponents(reportId: number) {
    try {
      const components = await this.api.listComponents({reportId});
      runInAction(() => {
        this.reportComponents = components;
      });
    } catch (error) {
      await this.handleError(error, true);
    }
  }

  async fetchAllContractOptions(hospitalId: number) {
    try {
      const analysisOptions = await this.api.listAnalysisTypeOptions({hospitalId});
      const allContractOptions: ContractOption[] = []
      for (var i = 0; i < analysisOptions.length; i++) {
        const analysisTypeId = analysisOptions[0].id
        const contractOptions = await this.api.listContractOptions({analysisTypeId, hospitalId});
        for (var j = 0; j < contractOptions.length; j++) {
          if (!allContractOptions.some(({contractCode}) => contractCode === contractOptions[j].contractCode)) {
            allContractOptions.push(contractOptions[j])
          }
        }
      }


      runInAction(() => {
        this.contractOptions = allContractOptions
      })
    } catch (error) {
      await this.handleError(error);
    }
  }

  async download(
    ids: number[],
    type: DownloadTypeEnum,
    categories: Set<DownloadCategoriesEnum>,
    trigger = true
  ) {
    try {
      this.isDownloading = true;
      const response = await this.api.downloadRaw({type, ids, categories});
      const blob = await response.value();

      if (trigger) {
        Data.downloadBlob(blob, Data.getFilename(response));
      } else {
        this.componentPreview = URL.createObjectURL(blob);
      }
      this.isDownloading = false;
    } catch (error) {
      this.isDownloading = false;
      await this.handleError(error);
    }
  }

  private static getFilename(res: ApiResponse<Blob>): string {
    let rawFilename = res.raw.headers
      .get('Content-Disposition')
      ?.split('filename=')[1];
    return (
      rawFilename?.substring(1, rawFilename.length - 1) || 'Unknown report'
    );
  }

  private static downloadBlob(blob: Blob, fileName: string) {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    document.body.append(link);
    link.click();
    link.remove();
    URL.revokeObjectURL(link.href);
  }

  async fetchHospitalOptions() {
    try {
      const options = await this.api.listHospitalOptions();
      runInAction(() => {
        this.hospitalOptions = options;
      });
    } catch (error) {
      await this.handleError(error);
    }
  }

  async fetchContractOptions(analysisTypeId: number, hospitalId: number) {
    try {
      const options = await this.api.listContractOptions({analysisTypeId, hospitalId});
      runInAction(() => {
        this.contractOptions = options;
      });
    } catch (error) {
      await this.handleError(error);
    }
  }

  async fetchConsentVersionOptions(hospitalId: number, contractCode: string) {
    try {
      const options = await this.api.listConsentVersionOptions({hospitalId, contractCode});
      runInAction(() => {
        this.consentVersionOptions = options;
      });
    } catch (error) {
      await this.handleError(error);
    }
  }

  async fetchAnalysisTypeOptions(hospitalId: number) {
    try {
      const options = await this.api.listAnalysisTypeOptions({hospitalId});
      runInAction(() => {
        this.analysisTypeOptions = options;
      });
    } catch (error) {
      await this.handleError(error);
    }
  }

  async fetchSampleTypeOptions(analysisTypeId: number) {
    try {
      const options = await this.api.listSampleTypeOptions({analysisTypeId});
      runInAction(() => {
        this.sampleTypeOptions = options;
      });
    } catch (error) {
      await this.handleError(error);
    }
  }

  async fetchTumorLocations() {
    try {
      const tumorLocations = await this.api.listTumorLocations();
      runInAction(() => {
        this.tumorLocations = tumorLocations.map((tumorLocation) => ({
          ...tumorLocation,
          matches: false
        }));
      });
    } catch (error) {
      this.tumorLocations = [];
      await this.handleError(error);
    }
  }

  async fetchTumorTypes(locationId: number) {
    try {
      const tumorTypes = await this.api.listTumorTypes({locationId});
      this.setTumorTypes(tumorTypes);
    } catch (error) {
      this.setTumorTypes([]);
      await this.handleError(error);
    }
  }

  setTumorTypes(tumorTypes: TumorType[]) {
    runInAction(() => {
      this.tumorTypes = tumorTypes.map((tumorTypes) => ({
        ...tumorTypes,
        matches: false
      }));
    });
  }

  async fetchTumorExtra(typeId: number) {
    try {
      this.setTumorExtra(await this.api.listTumorExtra({typeId}));
    } catch (error) {
      this.setTumorExtra([]);
      await this.handleError(error);
    }
  }

  setTumorExtra(tumorExtra: TumorExtra[]) {
    runInAction(() => {
      this.tumorExtra = tumorExtra.map((tumorExtra) => ({
        ...tumorExtra,
        matches: false
      }));
    });
  }

  async fetchBiopsyLocations() {
    try {
      this.setBiopsyLocations(await this.api.listBiopsyLocations());
    } catch (error) {
      this.setBiopsyLocations([]);
      await this.handleError(error);
    }
  }

  setBiopsyLocations(biopsyLocations: BiopsyLocation[]) {
    runInAction(() => {
      this.biopsyLocations = biopsyLocations.map((biopsyLocation) => ({
        ...biopsyLocation,
        matches: false
      }));
    });
  }

  async fetchBiopsySubLocations(locationId: number) {
    try {
      this.setBiopsySubLocations(await this.api.listBiopsySubLocations({locationId}));
    } catch (error) {
      this.setBiopsySubLocations([]);
      await this.handleError(error);
    }
  }

  setBiopsySubLocations(biopsySubLocations: BiopsySubLocation[]) {
    runInAction(() => {
      this.biopsySubLocations = biopsySubLocations.map((biopsySubLocation) => ({
        ...biopsySubLocation,
        matches: false
      }));
    });
  }

  getStudySampleIdStart(hospitalId: number, contractCodeToMatch: string, t): string {
    const contract: ContractOption | undefined = this.contractOptions.find(({contractCode}) => contractCode === contractCodeToMatch)
    const sampleIdStart = contract?.sampleIdStart;
    const hospitalCode = this.hospitalOptions.find(({id}) => id === Number(hospitalId))?.code;
    if ((sampleIdStart === undefined || hospitalCode === undefined) && contract?.samplesAreDiagnostic === false) {
      this.statusError = 404
      return t("validations.sampleIdStartNotFoundError")
    }
    return `${sampleIdStart}-${hospitalCode}-`;
  }

  async fetchTumorRegistrations() {
    try {
      const tumorRegistrations = await this.api.listTumorRegistrations();
      runInAction(() => {
        this.tumorRegistrations = tumorRegistrations.map((a) => ({
          ...a,
          startDate: parseDate(a.startDate!),
          isComplete: a.isComplete === true // do not simplify, to prevent undefined not showing up
        }));
      });
    } catch (error) {
      await this.handleError(error, true);
    }
  }

  async insertTumorRegistration(tumorRegistration: TumorRegistration) {
    try {
      const result = await this.api.insertTumorRegistration({tumorRegistration});
      runInAction(() => {
        this.tumorRegistrationProcess.tumorRegistration = result;
      });
    } catch (error) {
      await this.handleError(error);
    }
  }

  async getTumorRegistration(tumorRegistrationId: number) {
    try {
      const result = await this.api.getTumorRegistration({id: tumorRegistrationId});
      runInAction(() => {
        this.tumorRegistrationProcess.tumorRegistration = result;
      });
    } catch (error) {
      await this.handleError(error, true);
    }
  }

  async updateTumorRegistration(
    tumorRegistrationId: number,
    tumorRegistration: TumorRegistration
  ) {
    try {
      const result = await this.api.updateTumorRegistration({id: tumorRegistrationId, tumorRegistration});
      runInAction(() => {
        this.tumorRegistrationProcess.tumorRegistration = result;
      });
    } catch (error) {
      await this.handleError(error);
    }
  }

  async completeTumorRegistration(id: number) {
    try {
      await this.api.completeTumorRegistration({id});
    } catch (error) {
      await this.handleError(error);
    }
  }

  async deleteTumorRegistration(tumorRegistrationId: number) {
    try {
      await this.api.deleteTumorRegistration({id: tumorRegistrationId});
      delete this.tumorRegistrationProcess.tumorRegistration;
    } catch (error) {
      await this.handleError(error);
    }
  }

  async insertDiagnosticPatientInformation(
    tumorRegistrationId: number,
    diagnosticPatientInformation: DiagnosticPatientInformation
  ) {
    try {
      const result = await this.api.insertDiagnosticPatientInformation({
        diagnosticPatientInformation,
        tumorRegistrationId
      });
      runInAction(() => {
        this.tumorRegistrationProcess.diagnosticPatientInfo = result;
      });
    } catch (error) {
      await this.handleError(error);
    }
  }

  async getDiagnosticPatientInformationByTumorRegistration(
    tumorRegistrationId: number
  ) {
    try {
      const result = await this.api.getDiagnosticPatientInformationByTumorRegistration({tumorRegistrationId});
      runInAction(() => {
        this.tumorRegistrationProcess.diagnosticPatientInfo = result;
      });
    } catch (error) {
      await this.handleError(error, true);
    }
  }

  async updateDiagnosticPatientInformation(
    tumorRegistrationId: number,
    checkpointId: number,
    diagnosticPatientInformation: DiagnosticPatientInformation
  ) {
    try {
      const result = await this.api.updateDiagnosticPatientInformation({
        tumorRegistrationId,
        checkpointId,
        diagnosticPatientInformation
      });
      runInAction(() => {
        this.tumorRegistrationProcess.diagnosticPatientInfo = result;
      });
    } catch (error) {
      await this.handleError(error);
    }
  }

  async insertConsentInformation(
    tumorRegistrationId: number,
    consentInformation: ConsentInformation
  ) {
    try {
      const result = await this.api.insertConsentInformation({consentInformation, tumorRegistrationId});
      runInAction(() => {
        this.tumorRegistrationProcess.consentInfo = result;
      });
    } catch (error) {
      await this.handleError(error);
    }
  }

  async getConsentInformationByTumorRegistration(tumorRegistrationId: number) {
    try {
      const result = await this.api.getConsentInformationByTumorRegistration({tumorRegistrationId});
      runInAction(() => {
        this.tumorRegistrationProcess.consentInfo = result;
      });
    } catch (error) {
      await this.handleError(error, true);
    }
  }

  async updateConsentInformation(
    tumorRegistrationId: number,
    checkpointId: number,
    consentInformation: ConsentInformation
  ) {
    try {
      const result = await this.api.updateConsentInformation({
        tumorRegistrationId,
        checkpointId,
        consentInformation
      });
      runInAction(() => {
        this.tumorRegistrationProcess.consentInfo = result;
      });

    } catch (error) {
      await this.handleError(error);
    }
  }

  async insertTumorTypeInformation(
    tumorRegistrationId: number,
    tumorTypeInformation: TumorTypeInformation
  ) {
    try {
      const result = await this.api.insertTumorTypeInformation({tumorTypeInformation, tumorRegistrationId});
      runInAction(() => {
        this.tumorRegistrationProcess.tumorTypeInfo = result;
      });
    } catch (error) {
      await this.handleError(error);
    }
  }

  async getTumorTypeInformationByTumorRegistration(
    tumorRegistrationId: number
  ) {
    try {
      const result = await this.api.getTumorTypeInformationByTumorRegistration({tumorRegistrationId});
      runInAction(() => {
        this.tumorRegistrationProcess.tumorTypeInfo = result;
      });
    } catch (error) {
      await this.handleError(error, true);
    }
  }

  async updateTumorTypeInformation(
    tumorRegistrationId: number,
    checkpointId: number,
    tumorTypeInformation: TumorTypeInformation
  ) {
    try {
      const result = await this.api.updateTumorTypeInformation({
        tumorRegistrationId,
        checkpointId,
        tumorTypeInformation
      });
      runInAction(() => {
        this.tumorRegistrationProcess.tumorTypeInfo = result;
      });
    } catch (error) {
      await this.handleError(error);
    }
  }

  async insertBiopsySiteInformation(
    tumorRegistrationId: number,
    biopsySiteInformation: BiopsySiteInformation
  ) {
    try {
      const result = await this.api.insertBiopsySiteInformation({biopsySiteInformation, tumorRegistrationId});
      runInAction(() => {
        this.tumorRegistrationProcess.biopsySiteInfo = result;
      });
    } catch (error) {
      await this.handleError(error);
    }
  }

  async getBiopsySiteInformationByTumorRegistration(
    tumorRegistrationId: number
  ) {
    try {
      const result = await this.api.getBiopsySiteInformationByTumorRegistration({tumorRegistrationId});
      runInAction(() => {
        this.tumorRegistrationProcess.biopsySiteInfo = result;
      });
    } catch (error) {
      await this.handleError(error, true);
    }
  }

  async updateBiopsySiteInformation(
    tumorRegistrationId: number,
    checkpointId: number,
    biopsySiteInformation: BiopsySiteInformation
  ) {
    try {
      const result = await this.api.updateBiopsySiteInformation({
        tumorRegistrationId,
        checkpointId,
        biopsySiteInformation
      });
      runInAction(() => {
        this.tumorRegistrationProcess.biopsySiteInfo = result;
      });
    } catch (error) {
      await this.handleError(error);
    }
  }

  async insertSampleInformation(
    tumorRegistrationId: number,
    sampleInformation: SampleInformation
  ) {
    try {
      const result = await this.api.insertSampleInformation({sampleInformation, tumorRegistrationId});
      runInAction(() => {
        this.tumorRegistrationProcess.sampleInfo = result;
      });
    } catch (error) {
      await this.handleError(error);
    }
  }

  async getSampleInformationByTumorRegistration(tumorRegistrationId: number) {
    try {
      const result = await this.api.getSampleInformationByTumorRegistration({tumorRegistrationId});
      runInAction(() => {
        this.tumorRegistrationProcess.sampleInfo = result;
      });
    } catch (error) {
      await this.handleError(error, true);
    }
  }

  async updateSampleInformation(
    tumorRegistrationId: number,
    checkpointId: number,
    sampleInformation: SampleInformation
  ) {
    try {
      const result = await this.api.updateSampleInformation({
        tumorRegistrationId,
        checkpointId,
        sampleInformation
      });
      runInAction(() => {
        this.tumorRegistrationProcess.sampleInfo = result;
      });
    } catch (error) {
      await this.handleError(error);
    }
  }

  async generateRegistrationSummaryPdf(tumorRegistrationId: number) {
    try {
      const response = await this.api.generateRegistrationSummaryPdfRaw({tumorRegistrationId});
      const blob = await response.value();
      Data.downloadBlob(blob, Data.getFilename(response))
    } catch (error) {
      await this.handleError(error);
    }
  }

  async fetchReferenceRegistrations() {
    try {
      const result = await this.api.listReferenceRegistrations();
      runInAction(() => {
        this.referenceSamples = result;
      });
    } catch (error) {
      await this.handleError(error);
    }
  }

  async createReferenceRegistration(referenceRegistration: ReferenceRegistration) {
    try {
      await this.api.insertReferenceRegistration({referenceRegistration: referenceRegistration});
    } catch (error) {
      await this.handleError(error);
    }
  }

  async deleteReferenceRegistration(referenceSampleId: number) {
    try {
      await this.api.deleteReferenceRegistration({id: referenceSampleId});
    } catch (error) {
      await this.handleError(error);
    }
  }

  async handleError(error, ignore404: boolean = false) {
    if ('response' in error) {
      const {
        response: {status},
      } = error;
      if (status === 401) {
        try {
          await this.updateToken();
          return;
        } catch (error) {
          console.log(error);
        }
      }
      if (ignore404 && status === 404) return;

      this.statusError = status;
    } else {
      this.statusError = 500;
    }
  }

  resetReferenceRegistrationOptions() {
    runInAction(() => {
      this.hospitalOptions = [];
      this.analysisTypeOptions = [];
      this.contractOptions = [];
    });
  }

  resetTumorRegistrationOptions() {
    runInAction(() => {
      this.hospitalOptions = [];
      this.analysisTypeOptions = [];
      this.contractOptions = [];
      this.consentVersionOptions = [];
      this.sampleTypeOptions = [];
      this.tumorTypes = [];
      this.tumorLocations = [];
      this.tumorExtra = [];
      this.biopsyLocations = [];
      this.biopsySubLocations = [];
    });
  }
}
