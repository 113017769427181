import {Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Text,} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';

import ErrorIcon from '../assets/icons/error.svg';

const EMAIL = 'diagnosticssupport@hartwigmedicalfoundation.nl';

export default function ErrorModal({isOpen, onClose, statusError}) {
  const [t] = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay/>
      <ModalContent>
        <ModalCloseButton/>
        <ModalBody p={10} textAlign="center">
          <Text fontSize="2xl" fontWeight="bold">
            {t('modals.error.title')}
          </Text>
          <Text fontSize="lg">{t(`errorTexts.${statusError}`)}</Text>
          <img src={ErrorIcon} alt="Error" style={{margin: '20px auto'}}/>
          <Text fontSize="lg">
            {t('modals.error.tryAgain')}{' '}
            <a
              style={{textDecoration: 'underline', fontWeight: 'bold'}}
              href={`mailto:${EMAIL}`}
            >
              {EMAIL}
            </a>
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
