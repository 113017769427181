import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import {RefObject} from 'react';
import {useTranslation} from 'react-i18next';

export function Alert(
  {
    isOpen,
    onClose,
    cancelRef,
    title,
    text,
    onConfirm,
  }: {
    onClose: () => void;
    cancelRef: RefObject<any>;
    isOpen: boolean;
    title: string;
    text: string;
    onConfirm: any;
  }): JSX.Element {
  const [t] = useTranslation();
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay/>

      <AlertDialogContent>
        <AlertDialogHeader>{title}</AlertDialogHeader>
        <AlertDialogCloseButton/>
        <AlertDialogBody>{text}</AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose}>
            {t('modals.signOut.rejection')}
          </Button>
          <Button colorScheme="red" ml={3} onClick={onConfirm}>
            {t('modals.signOut.confirmation')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
