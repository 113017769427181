import {TimeIcon} from '@chakra-ui/icons';
import {Button, Center, Flex, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, Text,} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';

interface Props {
  isOpen: boolean;
  title: string;
  message?: string;
  onClose(): void;
  onSave(): void;
}

const ModalCompleteLater =
  ({
     isOpen,
     title,
     message,
     onClose,
     onSave,
   }: Props) => {
    const [t] = useTranslation();
    return (
      <Modal
        closeOnOverlayClick={false}
        size="xl"
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay/>
        <ModalContent>
          <ModalBody pt={6} pb={10}>
            <Flex direction={'column'} justifyContent="center">
              <Center>
                <TimeIcon
                  background="#304B99"
                  padding="10"
                  borderRadius="100"
                  color="white"
                  boxSize={150}
                />
              </Center>
              <Text
                letterSpacing={0.5}
                color="#000000"
                align={'center'}
                fontSize="18"
                mt={5}
              >
                {title}
              </Text>
              <Text
                letterSpacing={0.5}
                color="#000000"
                align={'center'}
                fontSize="18"
                mt={5}
              >
                {message}
              </Text>
            </Flex>
          </ModalBody>
          <ModalFooter justifyContent={'center'}>
            <Button
              bg={'white'}
              variant="outline"
              color="#304B99"
              boxShadow="md"
              borderColor="#304B99"
              fontSize={14}
              mr={3}
              onClick={onClose}
            >
              {t('modals.completeLater.cancel')}
            </Button>
            <Button
              color="white"
              bg="#304B99"
              boxShadow="md"
              variant="outline"
              borderColor="gray.300"
              fontSize={14}
              h={10}
              px={5}
              onClick={onSave}
            >
              {t('modals.completeLater.continue')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

export default ModalCompleteLater;
