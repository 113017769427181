import {Input, InputGroup, InputRightElement} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {CloseIcon, SearchIcon} from '@chakra-ui/icons';

export const Search = ({filter, onChange}) => {
  const [t] = useTranslation();
  return (
    <InputGroup borderColor="#6E6B7B" color="#6E6B73">
      <Input
        placeholder={t('table.actions.reports.search')}
        size="md"
        value={filter || ''}
        onChange={(e) => onChange(e.target.value)}
      />
      <InputRightElement
        children={
          !filter ? (
            <SearchIcon color="#6E6B7B"/>
          ) : (
            <CloseIcon
              color="#6E6B7B"
              onClick={() => onChange('')}
              cursor="pointer"
            />
          )
        }
      />
    </InputGroup>
  );
};
