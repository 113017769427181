import { FC, forwardRef, useCallback, useMemo } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDatePicker from 'react-datepicker';
import {
  Input,
  InputGroup,
  InputRightElement,
  StyleObjectOrFn,
  useTheme,
  css as chakraCSS,
  Flex,
  FormLabel,
  FormControl,
  FormErrorMessage,
  Select,
  Text,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { ClassNames } from '@emotion/react';
import { useField } from 'formik';
import { getMonth, getYear } from 'date-fns';
import range from 'lodash/range';
import { useTranslation } from 'react-i18next';
import { sameDateInUtc } from "./sameDateInUtc";

const CustomInput = forwardRef<any, any>((props, ref) => {
  return (
    <InputGroup>
      <Input {...props} placeholder={props.customplaceholder} ref={ref} />
      <InputRightElement
        userSelect="none"
        pointerEvents="none"
        children={<ChevronDownIcon />}
      />
    </InputGroup>
  );
});

function useDatePickerStyles() {
  const theme = useTheme();
  return useMemo(() => {
    const defaultStyles: StyleObjectOrFn = {
      p: 2,
      bg: 'white',
      border: '1px solid',
      borderColor: 'gray.100',
      boxShadow: 'sm',
      '& .react-datepicker': {
        '&__header': {
          bg: 'none',
          borderBottom: 'none',
        },
        '&__month': {
          mt: 0,
        },
        '&__day-name': {
          color: 'gray.400',
          fontWeight: 'medium',
          w: 7,
        },
        '&__day': {
          lineHeight: '28px',
          color: 'gray.700',
          w: 7,
          h: 7,
          borderRadius: 'full',
        },
        '&__day:not(.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected):hover':
          {
            bg: 'white',
            boxShadow: '0 0 1px 1px rgba(0,0,0,0.2)',
          },
        '&__day--today': {
          bg: 'gray.100',
          fontWeight: '400',
        },
        '&__day--selected, &__day--keyboard-selected': {
          bg: 'gray.700',
          color: 'white',
        },
      },
    };
    return chakraCSS(defaultStyles)(theme);
  }, [theme]);
}

export interface DatePickerProps {
  name: string;
  label?: string;
  dateFormat?: string;
  isRequired?: boolean;
  [x: string]: any;
}

export const ChakraDatePicker: FC<DatePickerProps> = ({
  label,
  dateFormat = 'dd-MM-yyyy',
  isRequired = false,
  ...props
}) => {
  const [t] = useTranslation();
  const styles = useDatePickerStyles();
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;

  const years = range(1910, getYear(new Date()) + 1, 1);
  let months: string[] = t('datePicker.months', { returnObjects: true });

  const render = useCallback(
    ({ css }) => {
      return (
        <FormControl
          isInvalid={meta.error?.length! > 0 && meta.touched}
          isRequired={isRequired}
        >
          <FormLabel
            fontSize="sm"
            htmlFor={label}
            requiredIndicator={
              <Text color="red" display="inline">
                *
              </Text>
            }
          >
            {label}
          </FormLabel>
          <ReactDatePicker
            {...props}
            dateFormat={dateFormat}
            showPopperArrow={false}
            popperClassName={css({ marginTop: '4px!important' })}
            calendarClassName={css(styles)}
            maxDate={new Date()}
            selected={field.value}
            name={field.name}
            popperProps={{
              strategy: 'fixed',
            }}
            onChange={(dateOrRange) => {
              if (dateOrRange === null) {
                setValue(null)
              } else {
                /*
                 * The picked date always has time 00:00:00 and the local time zone (e.g. +0200 in Amsterdam in summer).
                 * The API client converts the dates to strings with toISOString(), which changes the time zone to UTC.
                 * The date part of dates picked in other time zones will therefore shift (e.g. "2023-06-14T00:00:00+02:00"
                 * becomes "2023-06-13T22:00:00.000Z"). Solution: convert the picked date to one that will still have the
                 * same date part when converted to UTC.
                 */
                const date = Array.isArray(dateOrRange) ? dateOrRange[0] : dateOrRange;
                const dateInUtc = sameDateInUtc(date)
                setValue(dateInUtc);
              }
            }}
            customInput={<CustomInput customplaceholder={props.placeholder} />}
            renderCustomHeader={({ date, changeYear, changeMonth }) => (
              <Flex>
                <Select
                  value={getYear(date)}
                  size="sm"
                  onChange={({ target: { value } }) =>
                    changeYear(Number(value))
                  }
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
                <Select
                  size="sm"
                  value={months[getMonth(date)]}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              </Flex>
            )}
          />
          <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
      );
    },
    [styles, field.value, meta.error, label]
  );

  return <ClassNames>{render}</ClassNames>;
};
