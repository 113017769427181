import {FormControl, FormErrorMessage, FormLabel, NumberInput, NumberInputField, Text,} from '@chakra-ui/react';
import {useField} from 'formik';
import {FC} from 'react';

interface props {
  name: string;
  label?: string;
  placeholder?: string;
  variant?: string;
  id?: string;
  isRequired?: boolean;

  [x: string]: any;
}

export const NumberInputFormik: FC<props> =
  ({
     label,
     variant = 'flushed',
     placeholder,
     isRequired = false,
     ...props
   }: props) => {
    const [field, meta] = useField(props);
    return (
      <FormControl
        isInvalid={meta.error?.length! > 0 && meta.touched}
        isRequired={isRequired}
      >
        <FormLabel
          fontSize="sm"
          htmlFor={label}
          requiredIndicator={
            <Text color="red" display="inline">
              *
            </Text>
          }
        >
          {label}
        </FormLabel>
        <NumberInput defaultValue={field.value} {...field} variant={variant}>
          <NumberInputField {...field} placeholder={placeholder}/>
        </NumberInput>
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      </FormControl>
    );
  };
