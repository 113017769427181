import {DiagnosticPatientInformation, DiagnosticPatientInformationGenderEnum,} from '@hmf/portal-api-client';
import {useCallback, useEffect, useState} from 'react';
import {Data} from '../../../stores/data';
import * as Yup from 'yup';
import {useTranslation} from "react-i18next";

export interface StepDiagnosticPatientForm {
  initials: string;
  birthSurname: string;
  currentSurname: string;
  dateOfBirth?: Date;
  gender: string;
  postalCode: string;
  nameTreatingPhysician: string;
  emailTreatingPhysician: string;
}

export const useDiagnosticPatientInfo = (dataStore: Data) => {
  const [t] = useTranslation();

  const validationSchema = Yup.object().shape({
    initials: Yup.string() /*.required(`${t('validations.required')}`)*/,
    currentSurname: Yup.string() /*.required(`${t('validations.required')}`)*/,
    dateOfBirth: Yup.string() /*.required(`${t('validations.required')}`)*/,
    gender: Yup.string() /*.required(`${t('validations.required')}`)*/,
    postalCode: Yup.string().matches(
      /^[0-9]{4}[A-Z]{2}$/,
      `${t('validations.postalCodeFormatError')}`
    ),
    /*.required(`${t('validations.required')}`)*/ nameTreatingPhysician:
      Yup.string() /*.required(`${t('validations.required')}`)*/,
    emailTreatingPhysician: Yup.string().email(
      `${t('validations.emailFormatError')}`
    ),
    /*.required(`${t('validations.required')}`)*/
  });


  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const initialValuesForm = {
    initials:
      dataStore.tumorRegistrationProcess.diagnosticPatientInfo?.initials ?? '',
    birthSurname:
      dataStore.tumorRegistrationProcess.diagnosticPatientInfo?.birthSurname ??
      '',
    currentSurname:
      dataStore.tumorRegistrationProcess.diagnosticPatientInfo
        ?.currentSurname ?? '',
    dateOfBirth:
    dataStore.tumorRegistrationProcess.diagnosticPatientInfo?.dateOfBirth,
    gender:
      dataStore.tumorRegistrationProcess.diagnosticPatientInfo?.gender ?? '',
    postalCode:
      dataStore.tumorRegistrationProcess.diagnosticPatientInfo?.postalCode ??
      '',
    nameTreatingPhysician:
      dataStore.tumorRegistrationProcess.diagnosticPatientInfo
        ?.nameTreatingPhysician ?? '',
    emailTreatingPhysician:
      dataStore.tumorRegistrationProcess.diagnosticPatientInfo
        ?.emailTreatingPhysician ?? '',
  };

  const onSubmit = (values: StepDiagnosticPatientForm) => {
    try {
      const diagnosticPatientInformation: DiagnosticPatientInformation = {
        initials: values.initials || undefined,
        birthSurname: values.birthSurname || undefined,
        currentSurname: values.currentSurname || undefined,
        dateOfBirth: values.dateOfBirth || undefined,
        gender:
          (values.gender as DiagnosticPatientInformationGenderEnum) ||
          undefined,
        postalCode: values.postalCode || undefined,
        nameTreatingPhysician: values.nameTreatingPhysician || undefined,
        emailTreatingPhysician: values.emailTreatingPhysician || undefined,
      };
      if (dataStore.tumorRegistrationProcess.diagnosticPatientInfo) {
        diagnosticPatientInformation.id =
          dataStore.tumorRegistrationProcess.diagnosticPatientInfo.id;
        dataStore.updateDiagnosticPatientInformation(
          dataStore.tumorRegistrationProcess.tumorRegistration?.id!,
          dataStore.tumorRegistrationProcess.diagnosticPatientInfo.id!,
          diagnosticPatientInformation
        );
      } else {
        dataStore.insertDiagnosticPatientInformation(
          dataStore.tumorRegistrationProcess.tumorRegistration?.id!,
          diagnosticPatientInformation
        );
      }

      dataStore.handleTumorRegistrationProcess(
        dataStore.tumorRegistrationProcess.tumorRegistration?.id
      );
    } catch (error) {
      console.log(error);
    }
  };

  const initState = useCallback(() => {
    if (dataStore.tumorRegistrationProcess.diagnosticPatientInfo) {
      setIsDisabled(false);
    }
  }, [dataStore]);

  useEffect(() => initState(), [dataStore, initState]);

  return {
    initialValuesForm,
    isDisabled,
    onSubmit,
    initState,
    validationSchema
  };
};
