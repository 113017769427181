import React from 'react';
import {Text} from '@chakra-ui/react';
import {useTranslation} from "react-i18next";
import {isRuoGermlineFolder, isRuoFolder} from "../stores/data";

const ReportDisclaimer = ({ componentFolder }) => {
  const { t } = useTranslation();
  const getText = () => {
    if (isRuoFolder(componentFolder)) {
      return t('disclaimers.ruo');
    } else if (isRuoGermlineFolder(componentFolder)) {
      return t('disclaimers.ruoGermline');
    }
    return '';
  };

  const getColor = () => {
    return isRuoGermlineFolder(componentFolder) ? 'red.500' : 'gray.500';
  }

  return (
      <Text
        align='justify'
        border='1px solid'
        color={getColor()}
        dangerouslySetInnerHTML={{ __html: getText() }}
        fontSize='sm'
        mb={3}
        padding='16px'
        whiteSpace='pre-line'
      />
  );
};

export default ReportDisclaimer;