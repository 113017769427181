import {SampleInformation} from '@hmf/portal-api-client';
import {Data, LEGAL_CHARACTER_REGEX} from '../../../stores/data';
import * as Yup from 'yup';
import {useEffect} from 'react';
import {useTranslation} from "react-i18next";

export interface StepSampleInformationForm {
  sampleTypeId: number;
  samplingDate?: Date;
  hospitalPaSampleId: string;
  barcodes: string[];
  concentration?: number;
}

export const useSampleInformation = (dataStore: Data) => {
  const [t] = useTranslation()

  const validationSchema = Yup.object().shape({
    sampleTypeId: Yup.string().required(`${t('validations.required')}`),
    barcodes: Yup.array()
      .of(Yup.string().matches(LEGAL_CHARACTER_REGEX, `${t('validations.illegalCharacterError')}`))
      .min(1, `${t('validations.required')}`)
      .max(3, `${t('validations.maxBarcodeError')}`)
      .test(
        t('validations.duplicateBarcodeError'),
        () => `${t('validations.duplicateBarcodeError')}`,
        (values) => {
          return new Set(values).size === values!.length;
        }
      ),
    concentration: Yup.number().when('sampleTypeId', {
      is: (sampleTypeId: string) => {
        const sampleType = dataStore.sampleTypeOptions.find(({id}) => id.toString() === sampleTypeId);
        return sampleType?.name.includes('DNA') || sampleType?.name.includes('FFPE')
      },
      then: Yup.number()
        .required(`${t('validations.required')}`)
        .typeError(`${t('validations.concentrationFormatError')}`),
    })
  });

  const initialValuesForm: StepSampleInformationForm = {
    sampleTypeId: dataStore.tumorRegistrationProcess?.sampleInfo?.sampleTypeId!,
    samplingDate: dataStore.tumorRegistrationProcess.sampleInfo?.samplingDate,
    hospitalPaSampleId:
      dataStore.tumorRegistrationProcess.sampleInfo?.hospitalPaSampleId!,
    barcodes: dataStore.tumorRegistrationProcess.sampleInfo?.barcodes ?? [],
    concentration:
      dataStore.tumorRegistrationProcess?.sampleInfo?.concentration!,
  };

  const onSubmit = async (values) => {
    try {
      let sampleInformation: SampleInformation = {
        ...values,
        concentration: Number(values.concentration),
        sampleTypeId: values.sampleTypeId,
        samplingDate: values.samplingDate!,
      };

      if (dataStore.tumorRegistrationProcess.sampleInfo?.id) {
        sampleInformation.id =
          dataStore.tumorRegistrationProcess.sampleInfo?.id;
        await dataStore.updateSampleInformation(
          dataStore.tumorRegistrationProcess.tumorRegistration?.id!,
          dataStore.tumorRegistrationProcess.sampleInfo?.id,
          sampleInformation
        );
        dataStore.handleTumorRegistrationProcess(
          dataStore?.tumorRegistrationProcess.tumorRegistration?.id
        );
        dataStore.closeAccordionWizard(true);
      } else {
        dataStore.insertSampleInformation(
          dataStore.tumorRegistrationProcess.tumorRegistration?.id!,
          sampleInformation
        );
        dataStore.handleTumorRegistrationProcess(
          dataStore?.tumorRegistrationProcess.tumorRegistration?.id
        );
        dataStore.closeAccordionWizard(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dataStore.fetchSampleTypeOptions(
      dataStore.tumorRegistrationProcess.tumorRegistration?.analysisTypeId!
    );
  }, []);

  return {
    initialValuesForm,
    onSubmit,
    validationSchema
  };
};
