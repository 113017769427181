import { forwardRef, useCallback } from 'react';
import type {
  ForwardedRef,
  KeyboardEvent,
  SyntheticEvent,
  FocusEvent,
} from 'react';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  WrapItem,
  WrapItemProps,
  WrapProps,
  Text,
} from '@chakra-ui/react';
import type {
  InputProps,
  TagProps,
  TagLabelProps,
  TagCloseButtonProps,
} from '@chakra-ui/react';

import { maybeCall } from './functions';
import type { MaybeFunc } from './functions';
import ChakraTagInputTag from './Tag';
import { useField } from 'formik';

type MaybeIsInputProps<P> = MaybeFunc<[isInput: boolean, index?: number], P>;
type MaybeTagProps<P> = MaybeFunc<[tag: string, index?: number], P>;

export type ChakraTagInputProps = InputProps & {
  tags?: string[];
  onTagsChange?(event: SyntheticEvent, tags: string[]): void;
  onTagAdd?(event: SyntheticEvent, value: string): void;
  onTagRemove?(event: SyntheticEvent, index: number): void;
  vertical?: boolean;
  addKeys?: string[];
  wrapProps?: WrapProps;
  wrapItemProps?: MaybeIsInputProps<WrapItemProps>;
  tagProps?: MaybeTagProps<TagProps>;
  tagLabelProps?: MaybeTagProps<TagLabelProps>;
  tagCloseButtonProps?: MaybeTagProps<TagCloseButtonProps>;
  isRequired?: boolean;
  name: string;
  label?: string;
  placeholder?: string;
  [x: string]: any;
};

export default forwardRef(function ChakraTagInput(
  {
    tags = [],
    onTagsChange,
    onTagAdd,
    onTagRemove,
    vertical = false,
    addKeys = ['Enter'],
    wrapProps,
    wrapItemProps,
    tagProps,
    tagLabelProps,
    tagCloseButtonProps,
    label,
    isRequired = false,
    ...props
  }: ChakraTagInputProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const [field, meta, helpers] = useField({ name: props.name });
  const { setValue } = helpers;

  const addTag = useCallback(
    (event: SyntheticEvent, tag: string) => {
      onTagAdd?.(event, tag);
      if (event.isDefaultPrevented()) return;
      setValue(field.value.concat([tag]));
      onTagsChange?.(event, field.value.concat([tag]));
    },
    [field.value, onTagsChange, onTagAdd]
  );
  const removeTag = useCallback(
    (event: SyntheticEvent, index: number) => {
      onTagRemove?.(event, index);
      if (event.isDefaultPrevented()) return;
      setValue([
        ...field.value.slice(0, index),
        ...field.value.slice(index + 1),
      ]);
      onTagsChange?.(event, [
        ...field.value.slice(0, index),
        ...field.value.slice(index + 1),
      ]);
    },
    [field.value, onTagsChange, onTagRemove]
  );
  const handleRemoveTag = useCallback(
    (index: number) => (event: SyntheticEvent) => {
      removeTag(event, index);
    },
    [removeTag]
  );
  const onKeyDown = props.onKeyDown;
  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      onKeyDown?.(event);

      if (event.isDefaultPrevented()) return;
      if (event.isPropagationStopped()) return;

      const { currentTarget, key } = event;
      const { selectionStart, selectionEnd } = currentTarget;
      if (addKeys.indexOf(key) > -1 && currentTarget.value) {
        addTag(event, currentTarget.value);
        if (!event.isDefaultPrevented()) {
          currentTarget.value = '';
        }
        event.preventDefault();
      } else if (
        key === 'Backspace' &&
        field.value.length > 0 &&
        selectionStart === 0 &&
        selectionEnd === 0
      ) {
        removeTag(event, field.value.length - 1);
      }
    },
    [addKeys, field.value.length, addTag, removeTag, onKeyDown]
  );

  const onBlur = props.onBlur;
  const handleBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      onBlur?.(event);

      if (event.isDefaultPrevented()) return;
      if (event.isPropagationStopped()) return;

      const { currentTarget } = event;
      if (currentTarget.value) {
        addTag(event, currentTarget.value);
        if (!event.isDefaultPrevented()) {
          currentTarget.value = '';
        }
        event.preventDefault();
      }
    },
    [addTag, onBlur]
  );

  return (
    <Flex flexDirection="column" {...wrapProps}>
      <WrapItem
        flexGrow={1}
        {...maybeCall(wrapItemProps, true, field.value.length)}
      >
        <FormControl
          isInvalid={meta.error?.length! > 0 && meta.touched}
          isRequired={isRequired}
        >
          <FormLabel
            fontSize="sm"
            htmlFor={label}
            requiredIndicator={
              <Text color="red" display="inline">
                *
              </Text>
            }
          >
            {label}
          </FormLabel>
          <Input
            {...props}
            onKeyDown={handleKeyDown}
            ref={ref}
            onBlur={handleBlur}
          />
          <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
      </WrapItem>
      <Flex mt="5">
        {field.value.map((tag, index) => (
          <WrapItem {...maybeCall(wrapItemProps, false, index)} key={index}>
            <ChakraTagInputTag
              onRemove={handleRemoveTag(index)}
              tagLabelProps={maybeCall(tagLabelProps, tag, index)}
              tagCloseButtonProps={maybeCall(tagCloseButtonProps, tag, index)}
              colorScheme={props.colorScheme}
              size={props.size}
              {...maybeCall(tagProps, tag, index)}
            >
              {tag}
            </ChakraTagInputTag>
          </WrapItem>
        ))}
      </Flex>
    </Flex>
  );
});
