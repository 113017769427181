import {Data, TumorLocationSearch, TumorTypesSearch,} from '../../../stores/data';
import * as Yup from 'yup';
import {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {TumorExtra, TumorTypeInformation} from '@hmf/portal-api-client';
import {useTranslation} from "react-i18next";

export interface StepTumorForm {
  tumorLocation?: string;
  tumorType?: string;
  tumorExtra?: string;
  primaryTumorKnown: string;
}

export const useStepTumorType = (dataStore: Data) => {
  const [t] = useTranslation()
  const validationSchema = Yup.object().shape({
    tumorLocation: Yup.string().when('primaryTumorKnown', {
      is: 'true',
      then: Yup.string().required(`${t("validations.required")}`),
    }),
    tumorType: Yup.string()
      .when('primaryTumorKnown', {
        is: 'true',
        then: Yup.string().required(`${t("validations.required")}`),
      })
      .when('primaryTumorKnown', {
        is: 'CUP',
        then: Yup.string().required(`${t("validations.required")}`),
      }),
    primaryTumorKnown: Yup.string().required(`${t("validations.required")}`),
  });

  const primaryTumorKnown =
    dataStore.tumorRegistrationProcess.tumorTypeInfo?.primaryTumorKnown;
  const initialValuesForm: StepTumorForm = {
    tumorLocation:
      dataStore.tumorRegistrationProcess.tumorTypeInfo?.tumorLocationId?.toString(),
    tumorType:
      dataStore.tumorRegistrationProcess.tumorTypeInfo?.tumorTypeId?.toString(),
    tumorExtra:
      dataStore.tumorRegistrationProcess.tumorTypeInfo?.tumorExtraId?.toString(),
    primaryTumorKnown: primaryTumorKnown ? `${primaryTumorKnown}` : 'true',
  };

  const [search, setSearch] = useState<string>('');

  const [tumorLocations, setTumorLocations] = useState<TumorLocationSearch[]>([]);
  const [tumorTypes, setTumorTypes] = useState<TumorTypesSearch[]>([]);
  const [tumorExtra, setTumorExtra] = useState<TumorExtra[]>([]);

  const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearch(value);
  };

  const onSubmit = (values: StepTumorForm) => {
    try {
      let primaryTumorKnown;

      if (values.primaryTumorKnown === 'CUP') {
        primaryTumorKnown = true;
      } else {
        primaryTumorKnown = values.primaryTumorKnown === 'true';
      }

      let tumorType: TumorTypeInformation = {
        primaryTumorKnown,
      };

      if (tumorType.primaryTumorKnown) {
        tumorType.tumorLocationId = +values.tumorLocation!;
        tumorType.tumorTypeId = +values.tumorType!;
      }

      if (values.tumorExtra) {
        tumorType.tumorExtraId = +values.tumorExtra!;
      }

      if (dataStore.tumorRegistrationProcess.tumorTypeInfo?.id) {
        tumorType.id = dataStore.tumorRegistrationProcess.tumorTypeInfo?.id;
        dataStore.updateTumorTypeInformation(
          dataStore.tumorRegistrationProcess.tumorRegistration?.id!,
          dataStore.tumorRegistrationProcess.tumorTypeInfo?.id,
          tumorType
        );
        dataStore.handleTumorRegistrationProcess(
          dataStore?.tumorRegistrationProcess.tumorRegistration?.id
        );
      } else {
        dataStore.insertTumorTypeInformation(
          dataStore.tumorRegistrationProcess.tumorRegistration?.id!,
          tumorType
        );
        dataStore.handleTumorRegistrationProcess(
          dataStore?.tumorRegistrationProcess.tumorRegistration?.id
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChangePrimaryTumorKnown = () => {
    setSearch('');
  };

  const onChangeTumorLocationKnownCUP = (tumorLocationId: string) => {
    dataStore.fetchTumorTypes(Number(tumorLocationId));
    setSearch('');
  };

  const onChangeTumorLocation = (event: ChangeEvent<HTMLInputElement>) => {
    dataStore.fetchTumorTypes(Number(event.target.value));
    setSearch('');
  };

  const onChangeTumorType = (event: ChangeEvent<HTMLInputElement>) => {
    dataStore.fetchTumorExtra(Number(event.target.value));
    setSearch('');
  };

  const onChangeTumorExtra = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch('');
  };

  const initState = useCallback(() => {
    if (dataStore.tumorRegistrationProcess.tumorTypeInfo?.id) {
      if (dataStore.tumorRegistrationProcess.tumorTypeInfo?.tumorLocationId) {
        dataStore.fetchTumorTypes(
          Number(
            dataStore.tumorRegistrationProcess.tumorTypeInfo?.tumorLocationId
          )
        );
      }

      if (dataStore.tumorRegistrationProcess.tumorTypeInfo?.tumorTypeId) {
        dataStore.fetchTumorExtra(
          Number(dataStore.tumorRegistrationProcess.tumorTypeInfo?.tumorTypeId)
        );
      }
    }
  }, [dataStore]);

  useEffect(() => {
    dataStore.fetchTumorLocations();
    initState();
  }, [dataStore, initState]);

  useEffect(() => {
    setTumorLocations(dataStore.tumorLocations);
  }, [dataStore.tumorLocations]);

  useEffect(() => {
    setTumorTypes(dataStore.tumorTypes);
  }, [dataStore.tumorTypes]);

  useEffect(() => {
    setTumorExtra(dataStore.tumorExtra);
  }, [dataStore.tumorExtra]);

  return {
    initialValuesForm,
    tumorLocations,
    tumorTypes,
    tumorExtra,
    onSubmit,
    search,
    initState,
    onChangeSearch,
    onChangePrimaryTumorKnown,
    onChangeTumorLocationKnownCUP,
    onChangeTumorLocation,
    onChangeTumorExtra,
    onChangeTumorType,
    validationSchema
  };
};
