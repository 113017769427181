import {Accordion, Box, Button, Flex, Spacer, Text, Wrap, WrapItem,} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {Data} from '../../stores/data';
import {AlertConfirm} from '../Alerts/AlertConfirm';
import ModalCompleteLater from '../Modals/ModalCompleteLater';
import ModalRegistration from '../Modals/ModalRegistration';
import ModalSaving from '../Modals/ModalSaving';
import GeneralStepper from '../Stepper/GeneralStepper';
import {StepBiopsySiteInfo} from './components/StepBiopsySiteInfo';
import {StepConsent} from './components/StepConsent';
import StepDiagnosticPatientInfo from './components/StepDiagnosticPatientInfo';
import {StepRequest} from './components/StepRequest';
import {StepSampleInformation} from './components/StepSampleInformation';
import {StepTumorType} from './components/StepTumorType';
import {useTumorSampleRegistration} from './hooks/useTumorSampleRegistration';
import React from "react";

export interface TumorSampleRegistrationProps {
  patientRegistrationId?: number;
  dataStore: Data;
}

export const TumorSampleRegistrationProcess: React.FC<TumorSampleRegistrationProps> =
  observer(({patientRegistrationId, dataStore}) => {
    const [t] = useTranslation();
    const {processPercentage} = dataStore.tumorRegistrationProcess;
    const {
      defaultIndex,
      isDisabledSubmit,
      stepRequestForm,
      currentStep,
      onCancelProcess,
      saving,
      completeLater,
      continueButton,
      onBackModal,
      onNewRegister,
      onChangeAccordion,
      setIsDisabledSubmit,
      isOpenModalCompleteLater,
      onCLoseModalCompleteLater,
      onOpenModalCompleteLater,
      isOpenModalSaving,
      isOpenModalFinish,
      onFinishRegister,
      onCLoseModalFinish,
      messageSaving,
      isOpenCancel,
      onOpenCancel,
      cancelRef,
      onCloseCancel,
    } = useTumorSampleRegistration(dataStore, patientRegistrationId);
    let stepMap = {
      REGISTRATION_SETUP: t('tumorRegistrationProcess.registrationSetup.title'),
      PATIENT_CONSENT: t('tumorRegistrationProcess.consent.title'),
      DIAGNOSTIC_PATIENT_INFORMATION: t('tumorRegistrationProcess.diagnosticPatientInfo.title'),
      TUMOR_TYPE: t('tumorRegistrationProcess.tumorType.title'),
      BIOPSY_SITE_INFO: t('tumorRegistrationProcess.biopsySite.title'),
      SAMPLE_DETAILS: t('tumorRegistrationProcess.sampleInfo.title'),
    };

    let completeLaterMessage: string = t('modals.completeLater.message').replace('current_step', stepMap[currentStep]);

    return (
      <>
        <Flex direction="column" height="full">
          <Flex mt="7px">
            <Wrap marginX={5} mb={18} mt={2}>
              <Text
                marginTop="15"
                marginLeft="5"
                textTransform="uppercase"
                letterSpacing={0.5}
                color="#5E5873"
                fontSize="18"
              >
                {t('table.actions.tumorRegistration.title')}
              </Text>
            </Wrap>
            <Spacer></Spacer>
            <GeneralStepper ProgressValue={processPercentage}/>
          </Flex>

          <Box borderWidth="1px" borderTop={0} marginX={5} mb={5}>
            <Accordion
              width={'100%'}
              index={defaultIndex !== undefined ? [defaultIndex] : []}
              allowMultiple
              onChange={onChangeAccordion}
            >
              {dataStore.tumorRegistrationProcess.steps.map((step, index) => {
                if (step.step === 'REGISTRATION_SETUP' && step.shouldRender) {
                  return (
                    <StepRequest
                      key={index}
                      innerRef={stepRequestForm[step.step]}
                      onValidate={(isValid) => {
                        if (step.active) {
                          setIsDisabledSubmit(!isValid);
                        }
                      }}
                      active={step.active}
                      edit={step.edit}
                      dataStore={dataStore}
                    />
                  );
                }
                if (step.step === 'PATIENT_CONSENT' && step.shouldRender) {
                  return (
                    <StepConsent
                      key={index}
                      innerRef={stepRequestForm[step.step]}
                      onValidate={(isValid) => {
                        if (step.active) {
                          setIsDisabledSubmit(!isValid);
                        }
                      }}
                      active={step.active}
                      edit={step.edit}
                      dataStore={dataStore}
                    />
                  );
                }
                if (step.step === 'DIAGNOSTIC_PATIENT_INFORMATION' && step.shouldRender) {
                  return (
                    <StepDiagnosticPatientInfo
                      key={index}
                      innerRef={stepRequestForm[step.step]}
                      edit={step.edit}
                      onValidate={(isValid) => {
                        if (step.active) {
                          setIsDisabledSubmit(!isValid);
                        }
                      }}
                      active={step.active}
                      dataStore={dataStore}
                    />
                  );
                }
                if (step.step === 'TUMOR_TYPE' && step.shouldRender) {
                  return (
                    <StepTumorType
                      key={index}
                      innerRef={stepRequestForm[step.step]}
                      active={step.active}
                      edit={step.edit}
                      onValidate={(isValid) => {
                        if (step.active) {
                          setIsDisabledSubmit(!isValid);
                        }
                      }}
                      dataStore={dataStore}
                    />
                  );
                }

                if (step.step === 'BIOPSY_SITE_INFO' && step.shouldRender) {
                  return (
                    <StepBiopsySiteInfo
                      key={index}
                      innerRef={stepRequestForm[step.step]}
                      onValidate={(isValid) => {
                        if (step.active) {
                          setIsDisabledSubmit(!isValid);
                        }
                      }}
                      active={step.active}
                      edit={step.edit}
                      dataStore={dataStore}
                    />
                  );
                }

                if (step.step === 'SAMPLE_DETAILS' && step.shouldRender) {
                  return (
                    <StepSampleInformation
                      key={index}
                      innerRef={stepRequestForm[step.step]}
                      onValidate={(isValid) => {
                        if (step.active) {
                          setIsDisabledSubmit(!isValid);
                        }
                      }}
                      active={step.active}
                      edit={step.edit}
                      dataStore={dataStore}
                    />
                  );
                }

                return <></>;
              })}
            </Accordion>

            <Flex minWidth="max-content" alignItems="center" gap="2">
              <Spacer/>
              <Wrap marginX="2" mt="5">
                <WrapItem h={12}>
                  <Button
                    color="#304B99"
                    bg={'white'}
                    boxShadow="md"
                    variant="outline"
                    borderColor="#304B99"
                    fontSize={14}
                    h={10}
                    px={5}
                    onClick={onOpenCancel}
                  >
                    {t('tumorRegistrationProcess.actions.cancel')}
                  </Button>
                </WrapItem>

                {dataStore.tumorRegistrationProcess.processPercentage < 100 && (
                  <WrapItem h={12}>
                    <Button
                      color="#304B99"
                      bg={'white'}
                      boxShadow="md"
                      variant="outline"
                      borderColor="#304B99"
                      fontSize={14}
                      h={10}
                      px={5}
                      onClick={onOpenModalCompleteLater}
                    >
                      {t('tumorRegistrationProcess.actions.completeLater')}
                    </Button>
                  </WrapItem>
                )}

                {dataStore.tumorRegistrationProcess.processPercentage < 100 && (
                  <WrapItem h={12}>
                    <Button
                      color="white"
                      bg="#304B99"
                      boxShadow="md"
                      variant="outline"
                      borderColor="gray.300"
                      fontSize={14}
                      h={10}
                      px={5}
                      disabled={isDisabledSubmit}
                      onClick={continueButton}
                    >
                      {t('tumorRegistrationProcess.actions.continue')}
                    </Button>
                  </WrapItem>
                )}

                {dataStore.tumorRegistrationProcess.processPercentage ===
                  100 && (
                    <WrapItem h={12}>
                      <Button
                        color="white"
                        bg="#304B99"
                        boxShadow="md"
                        variant="solid"
                        borderColor="gray.300"
                        fontSize={14}
                        h={10}
                        px={5}
                        onClick={async () => {
                          if (
                            dataStore.tumorRegistrationProcess
                              .processPercentage === 100
                          ) {
                            if (
                              dataStore.tumorRegistrationProcess.tumorRegistration
                                ?.id
                            ) {
                              await onFinishRegister();
                            }
                          }
                        }}
                      >
                        {t('tumorRegistrationProcess.actions.finish')}
                      </Button>
                    </WrapItem>
                  )}
              </Wrap>
            </Flex>
          </Box>
        </Flex>

        <ModalCompleteLater
          title={t('modals.completeLater.title')}
          message={completeLaterMessage}
          isOpen={isOpenModalCompleteLater}
          onClose={onCLoseModalCompleteLater}
          onSave={completeLater}
        />

        <AlertConfirm
          title={t('modals.cancel.title')}
          message={t('modals.cancel.message')}
          buttonCancel={t('modals.cancel.no')}
          actionButton={t('modals.cancel.yes')}
          isOpen={isOpenCancel}
          leastDestructiveRef={cancelRef}
          onClose={onCloseCancel}
          onAccept={onCancelProcess}
        />

        <ModalSaving
          title={t(messageSaving)}
          isOpen={isOpenModalSaving}
          loading={saving}
        />

        <ModalRegistration
          isOpen={isOpenModalFinish}
          onClose={onCLoseModalFinish}
          onRegister={onNewRegister}
          onBack={onBackModal}
        />
      </>
    );
  });
