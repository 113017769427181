/*
 * Only uses the date part of the provided date, and returns a date with a time that will
 * still yield the same date when converted to UTC.
 */
export function sameDateInUtc(date: Date): Date {
  const differenceInMinutesWithUtc = date.getTimezoneOffset()

  const dateInUtc = new Date(date)
  dateInUtc.setHours(-1 * (differenceInMinutesWithUtc / 60))
  dateInUtc.setMinutes(-1 * (differenceInMinutesWithUtc % 60)) // account for time zones with 30-minute and 45-minute offsets
  dateInUtc.setSeconds(0)
  dateInUtc.setMilliseconds(0)

  return dateInUtc;
}
