import {Center, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Spinner,} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';

const FilePreview = (
  {
    onClose,
    isOpen,
    data,
    type = 'application/pdf',
  }: {
    onClose: () => void;
    isOpen: boolean;
    data: string | null;
    type?: string;
  }) => {
  const [t] = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onEsc={onClose}
      onOverlayClick={onClose}
    >
      <ModalOverlay/>
      <ModalContent height="80vh" maxW="90vw">
        <ModalCloseButton/>
        <ModalBody mr={25}>
          {!data ? (
            <Center h="50vh">
              <Spinner/>
            </Center>
          ) : (
            <object data={data} width="100%" height="100%" type={type}>
              <p>{t('filePreviewError')}</p>
            </object>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FilePreview;
