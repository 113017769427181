import {
  Button,
  Checkbox,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';

import {useTranslation} from 'react-i18next';

import {DownloadCategoriesEnum} from '@hmf/portal-api-client';
import {CloseIcon, DownloadIcon} from '@chakra-ui/icons';
import {useState} from 'react';

enum DownloadOption {
  ReportFiles, OtherFiles
}

const CHECKBOXES = [
  {
    option: DownloadOption.ReportFiles,
    translationKey: 'modals.download.reportFiles',
    downloadCategory: DownloadCategoriesEnum.Report
  },
  {
    option: DownloadOption.OtherFiles,
    translationKey: 'modals.download.otherFiles',
    downloadCategory: DownloadCategoriesEnum.Other
  },
];

export default function ReportDownloadFilter({
                                               isOpen,
                                               onAccept,
                                               onClose,
                                               isDownloading,
                                             }) {
  const [t] = useTranslation();
  const [downloadOptions, setDownloadOptions] = useState<DownloadOption[]>(
    [DownloadOption.ReportFiles, DownloadOption.OtherFiles]
  );

  const download = () => {
    const downloadFolders = CHECKBOXES
      .filter(checkbox => downloadOptions.includes(checkbox.option))
      .map(checkbox => checkbox.downloadCategory);
    onAccept(downloadFolders);
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay/>
      <ModalContent>
        <ModalBody p={10}>
          <VStack justifyContent="center" spacing={5}>
            <Text textTransform="uppercase">{t('modals.download.title')}</Text>
            {isDownloading ? (
              <Spinner size="xl" thickness="4px"/>
            ) : (
              <HStack spacing={5}>
                {CHECKBOXES.map(({option, translationKey}) => (
                  <Checkbox
                    key={translationKey}
                    value={option}
                    isChecked={downloadOptions.includes(option)}
                    onChange={e =>
                      e.target.checked
                        ? setDownloadOptions([...downloadOptions, option])
                        : setDownloadOptions(downloadOptions.filter((o => o !== option)))
                    }
                  >
                    {t(translationKey)}
                  </Checkbox>
                ))}
              </HStack>
            )}
            <HStack spacing={2.5}>
              <Button
                fontSize={14}
                h={10}
                backgroundColor="white"
                boxShadow="md"
                variant="outline"
                borderColor="gray.300"
                rightIcon={<DownloadIcon fontSize={20}/>}
                paddingX={5}
                disabled={downloadOptions.length === 0}
                onClick={download}
              >
                {t('table.actions.reports.download')}
              </Button>
              <Button
                fontSize={14}
                h={10}
                backgroundColor="white"
                boxShadow="md"
                variant="outline"
                borderColor="gray.300"
                paddingX={5}
                onClick={onClose}
              >
                <CloseIcon
                  position="absolute"
                  padding={0.5}
                  top={-1.5}
                  right={-1.5}
                  shadow="xl"
                  border="1px solid"
                  borderColor="gray.300"
                  borderRadius={3}
                  boxShadow="md"
                  backgroundColor="white"
                />
                {t('table.actions.reports.cancel')}
              </Button>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
