import {useCallback, useEffect, useState} from 'react';
import {Data} from '../../../stores/data';
import * as Yup from 'yup';
import {ConsentInformation, ConsentInformationConsentGivenEnum,} from '@hmf/portal-api-client';
import {useTranslation} from "react-i18next";


export interface StepConsentForm {
  consentGiven: string;
  consentVersion?: number;
}

export const useConsent = (dataStore: Data) => {
  const [t] = useTranslation()
  const validationSchema = Yup.object().shape({
    consentGiven: Yup.string().required(`${t("validations.required")}`),
    consentVersion: Yup.number().when('consentGiven', {
      is: (consentGiven: string) =>
        consentGiven === ConsentInformationConsentGivenEnum.Yes.valueOf() || consentGiven === ConsentInformationConsentGivenEnum.No.valueOf(),
      then: Yup.number().required(`${t("validations.required")}`),
    }),
  });

  const {consentVersionOptions} = dataStore;

  const initialValuesForm: StepConsentForm = {
    consentGiven:
      dataStore.tumorRegistrationProcess.consentInfo?.consentGiven ?? '',
    consentVersion:
    dataStore.tumorRegistrationProcess.consentInfo?.consentVersionId,
  };

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const onSubmit = (values: StepConsentForm) => {
    try {
      let consentInformation: ConsentInformation = {
        consentVersionId:
          values.consentGiven === 'YES' || values.consentGiven === 'NO'
            ? values.consentVersion
            : undefined,
        consentGiven: values.consentGiven as ConsentInformationConsentGivenEnum,
      };
      if (dataStore.tumorRegistrationProcess.consentInfo?.id) {
        consentInformation.id =
          dataStore.tumorRegistrationProcess.consentInfo.id;
        dataStore.updateConsentInformation(
          dataStore.tumorRegistrationProcess.tumorRegistration?.id!,
          dataStore.tumorRegistrationProcess.consentInfo.id,
          consentInformation
        );
      } else {
        dataStore.insertConsentInformation(
          dataStore.tumorRegistrationProcess.tumorRegistration?.id!,
          consentInformation
        );
      }

      dataStore.handleTumorRegistrationProcess(
        dataStore.tumorRegistrationProcess.tumorRegistration?.id
      );
    } catch (error) {
      console.log(error);
    }
  };

  const initState = useCallback(() => {
    if (dataStore.tumorRegistrationProcess.tumorRegistration) {
      dataStore.fetchConsentVersionOptions(
        dataStore.tumorRegistrationProcess.tumorRegistration.hospitalId,
        dataStore.tumorRegistrationProcess.tumorRegistration?.contractCode!
      );
    }
  }, [dataStore]);

  useEffect(() => initState(), [dataStore, initState]);

  return {
    initialValuesForm,
    consentVersionOptions,
    isDisabled,
    initState,
    onSubmit,
    setIsDisabled,
    validationSchema
  };
};
