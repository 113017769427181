import { CheckIcon } from '@chakra-ui/icons';
import {
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
} from '@chakra-ui/react';

interface Props {
  isOpen: boolean;
  loading: boolean;
  title: string;
}

const ModalSaving = ({ isOpen, title = '', loading = true }: Props) => {
  return (
    <Modal
      closeOnOverlayClick={false}
      size="xl"
      isOpen={isOpen}
      isCentered
      onClose={() => {}}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody pt={6} pb={10}>
          <Flex direction={'column'} justifyContent="center">
            <Text
              letterSpacing={0.5}
              color="#000000"
              align={'center'}
              fontSize="18"
              mt={5}
            >
              {title}
            </Text>
            <Center mt={5}>
              {loading ? <Spinner size="xl" /> : <CheckIcon fontSize="xl" />}
            </Center>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalSaving;
