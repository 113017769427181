import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Flex,
  Spacer,
  Text,
  useDisclosure,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import {observer} from "mobx-react-lite";
import {useEffect, useState} from "react";
import {Data, isRuoFolder, isRuoGermlineFolder} from "../stores/data";
import {ChevronLeftIcon, CloseIcon, DownloadIcon, ViewIcon,} from "@chakra-ui/icons";
import {parseDate, parseTime} from "../utils";
import {ReportComponentInfo, ReportComponentInfoFileTypeEnum} from '@hmf/portal-api-client';
import {useTranslation} from "react-i18next";
import bytes from "bytes";
import FilePreview from "./FilePreview";
import ReportDisclaimer from "./ReportDisclaimer";
import TableComponent from "./Table";
import {FILE_ICONS, Structures} from "./ColumnsTable";
import ModalConfidentialInfo from "./Modals/ModalConfidentialInfo";

const ROWS_ORDER = [
  "reportedDate",
  "identifier",
  "pathologyNumber",
  "reportType",
];
const ROWS_TEXT = ['date', 'identifier', 'pathologyNumber', 'type'];

const parseOtherFilesByFolder = (otherFiles: ReportComponentInfo[]) =>
  otherFiles.reduce((acc, curr) => {
    if (acc[curr.folder as string]) {
      acc[curr.folder as string].count = acc[curr.folder as string].count + 1;
      acc[curr.folder as string].size =
        (curr.sizeInBytes as number) + acc[curr.folder as string].size;
      return acc;
    }

    acc[curr.folder as string] = {
      count: 1,
      size: curr.sizeInBytes as number,
    };

    return acc;
  }, {} as { [K: string]: { count: number; size: number } });

const parseMainFiles = (reports: ReportComponentInfo[]) =>
  reports
    .filter((f) => f.folder === "")
    .sort((f1, _) => (f1.fileType === ReportComponentInfoFileTypeEnum.Pdf ? -1 : 1));

const parseOtherFiles = (reports: ReportComponentInfo[]) =>
  reports.filter((f) => f.folder !== "");

const ReportDetails = observer(
  ({dataStore, onCloseSlider}: { dataStore: Data; onCloseSlider }) => {
    const {reportComponents: reports, selectedAnalysis: sample} = dataStore;
    const [filesToDownload, setFilesToDownload] = useState<number[]>([]);
    const [isToggled, setIsToggled] = useState<boolean>(false);
    const [toggledFolder, setToggledFolder] = useState<string>('');
    const [fileToPreview, setFileToPreview] =
      useState<ReportComponentInfo | null>(null);

    const {isOpen: isFilePreviewOpen, onOpen: openFilePreview, onClose: closeFilePreview} = useDisclosure({defaultIsOpen: false});
    const {
      isOpen: isGermlineWarningOpen,
      onOpen: openGermlineWarning,
      onClose: closeGermlineWarning
    } = useDisclosure({defaultIsOpen: false});

    const [mainFiles, setMainFiles] = useState(parseMainFiles(reports));
    const [otherFiles, setOtherFiles] = useState(parseOtherFiles(reports));
    const [otherFilesByFolder, setOtherFilesByFolder] = useState(
      parseOtherFilesByFolder(otherFiles)
    );

    const [t] = useTranslation();

    useEffect(() => {
      setFilesToDownload([]);
      dataStore.fetchAnalyses();
      dataStore.fetchReportComponents(sample?.reportId as number);
    }, [dataStore.selectedAnalysis]);

    useEffect(() => {
      setMainFiles(parseMainFiles(dataStore.reportComponents));
      setOtherFiles(parseOtherFiles(dataStore.reportComponents));
    }, [dataStore.reportComponents]);

    useEffect(() => {
      setOtherFilesByFolder(parseOtherFilesByFolder(otherFiles));
    }, [otherFiles]);

    useEffect(() => {
      if (fileToPreview) downloadFiles(true, fileToPreview);
    }, [isFilePreviewOpen]);

    useEffect(() => {
      dataStore.fetchAnalyses();
      dataStore.fetchReportComponents(sample?.reportId as number);
    }, []);

    const downloadFiles = (
      toPreview: boolean = false,
      previewFile?: ReportComponentInfo
    ) => {
      dataStore
        .download(
          toPreview ? [previewFile?.id as number] : filesToDownload,
          'COMPONENT',
          new Set(),
          !toPreview
        )
        .then((_) => {
          dataStore.fetchAnalyses();

          if (dataStore.selectedAnalysis) {
            dataStore.fetchReportComponents(dataStore.selectedAnalysis.reportId);
          }
        });
    };

    const allMain = () => {
      const files = mainFiles.map((r) => r.id as number);

      if (mainFilesSelected()) {
        setFilesToDownload(filesToDownload.filter((f) => !files.includes(f)));
      } else {
        setFilesToDownload([...filesToDownload, ...files]);
      }
    };

    const mainFilesSelected = () => {
      const files = mainFiles.map((r) => r.id as number);
      return files.some((f) => filesToDownload.includes(f as number));
    };

    const isPDF = (item: ReportComponentInfo): boolean => {
      return item.fileType === ReportComponentInfoFileTypeEnum.Pdf;
    };

    const previewPDF = (e, item: ReportComponentInfo, idx: number) => {
      if (!isPDF(item) || e.target.id !== `${idx}-item`) {
        return;
      }
      openFilePreview();
      setFileToPreview(item);
    };

    function oncoActText(item: ReportComponentInfo) {
      switch (item.fileType) {
        case ReportComponentInfoFileTypeEnum.Pdf:
          return "OncoAct PDF";
        case ReportComponentInfoFileTypeEnum.Json:
          return "OncoAct JSON";
        case ReportComponentInfoFileTypeEnum.Xml:
          return "OncoAct XML";
      }
    }

    return (
      <Box
        h="100%"
        w="100%"
        color="black"
        overflowY="scroll"
        p={5}
        pt={0}
      >
        {isToggled ? (
          <>
            <Flex mb={3}>
              <ChevronLeftIcon
                fontSize="30px"
                color="#304B99"
                cursor="pointer"
                alignItems="center"
                mr={3}
                onClick={() => {
                  setIsToggled(!isToggled);
                  setToggledFolder('');
                }}
              />
              <Text fontSize="lg" color="#304B99" flex={1}>
                {toggledFolder}
              </Text>
              <Spacer/>
              <Button
                h={10}
                px={5}
                mr={3}
                variant="outline"
                borderColor="gray.300"
                boxShadow="md"
                bgColor="white"
                fontSize={14}
                rightIcon={<DownloadIcon marginLeft="2px" w={4} h={4}/>}
                onClick={() => downloadFiles(false)}
                disabled={!filesToDownload.length}
              >
                {t('sampleDetails.viewAllFiles')}
              </Button>
              <Button
                fontSize={14}
                h={10}
                px={5}
                boxShadow="md"
                bgColor="white"
                variant="outline"
                borderColor="gray.300"
                paddingX={5}
                onClick={() => setFilesToDownload([])}
                disabled={!filesToDownload.length}
              >
                {t('table.actions.reports.cancel')}
              </Button>
              <Spacer/>
            </Flex>
            <Flex direction="column">
              {(isRuoFolder(toggledFolder) || isRuoGermlineFolder(toggledFolder)) &&
                  <ReportDisclaimer componentFolder={toggledFolder}/>
              }
              <TableComponent
                columns={{
                  structure: Structures.FILES,
                  texts: 'table.files',
                }}
                data={otherFiles.filter(
                  (of) => of.folder === toggledFolder
                )}
                paginable={false}
                isCheckedCheckboxHeader={() =>
                  otherFiles
                    .filter((of) => of.folder === toggledFolder)
                    .every((of) => filesToDownload.some((f) => f === of.id))
                }
                onClickCheckboxHeader={(isChecked) =>
                  isChecked
                    ? setFilesToDownload([
                      ...filesToDownload,
                      ...otherFiles
                        .filter((of) => of.folder === toggledFolder)
                        .map((f) => f.id as number),
                    ])
                    : setFilesToDownload(
                      filesToDownload.filter(
                        (f) => !otherFiles.some((of) => of.id === f)
                      )
                    )
                }
                isCheckedCheckboxBody={(id) =>
                  filesToDownload.includes(id as number)
                }
                onClickCheckboxBody={(isChecked, id) =>
                  isChecked
                    ? setFilesToDownload([...filesToDownload, id as number])
                    : setFilesToDownload(
                      filesToDownload.filter((f) => f !== id)
                    )
                }
                includeActionButton={false}
              />
            </Flex>
          </>
        ) : (
          <>
            <Text
              fontSize="lg"
              color="#304B99"
              textTransform="uppercase"
              display="flex"
            >
              {sample?.identifier}
            </Text>

            <Flex flexWrap="wrap" mt={5} rowGap={1.5} columnGap={2}>
              {ROWS_ORDER.map((v, i) => (
                <Flex key={v} flexDirection="row" flex="48%" minW="48%">
                  <Text
                    fontSize={13}
                    width="30%"
                    alignSelf="center"
                    pl={{md: 2, xl: 5, '2xl': 7}}
                  >
                    {t(`sampleDetails.${ROWS_TEXT[i]}`)}
                  </Text>
                  <Text
                    bg="#E6EAF0"
                    alignSelf="center"
                    p={1}
                    borderRadius="2px"
                    width="70%"
                    h={30}
                    maxH={30}
                    overflowY="visible"
                    fontSize={13}
                  >
                    {String(sample![v])}
                  </Text>
                </Flex>
              ))}
            </Flex>

            <Center>
              <Wrap
                mt={30}
                p={1}
                spacing={5}
                __css={{ul: {justifyContent: 'center'}}}
              >
                <WrapItem>
                  <Button
                    fontSize={14}
                    variant="outline"
                    borderColor="gray.300"
                    boxShadow="md"
                    bgColor="white"
                    onClick={allMain}
                    h={10}
                    px={5}
                  >
                    {mainFilesSelected()
                      ? t('sampleDetails.deselectAll')
                      : t('sampleDetails.selectAll')}
                  </Button>
                </WrapItem>
                <WrapItem>
                  <Button
                    h={10}
                    px={5}
                    variant="outline"
                    borderColor="gray.300"
                    boxShadow="md"
                    bgColor="white"
                    fontSize={14}
                    rightIcon={
                      <DownloadIcon marginLeft="2px" w={4} h={4}/>
                    }
                    onClick={() => downloadFiles(false)}
                    disabled={!filesToDownload.length}
                  >
                    {t('sampleDetails.download')}
                  </Button>
                </WrapItem>
              </Wrap>
            </Center>

            <Text fontSize="sm" pl={5} pt={5} textTransform="uppercase">
              {t('sampleDetails.reports')}:
            </Text>
            <Wrap spacing={[10, 20, 5]} justify="center" py={5}>
              {mainFiles.map((item, i) => (
                <WrapItem key={item.id}>
                  <Box
                    p="3"
                    border="1px"
                    borderColor="grey"
                    borderRadius="10px"
                    w={160}
                    minH={250}
                    shadow="lg"
                    wordBreak="break-all"
                  >
                    <Checkbox
                      position="relative"
                      top="0"
                      left="95%"
                      border="gray"
                      isChecked={filesToDownload.includes(
                        item.id as number
                      )}
                      onChange={(e) =>
                        e.target.checked
                          ? setFilesToDownload([
                            ...filesToDownload,
                            item.id as number,
                          ])
                          : setFilesToDownload(
                            filesToDownload.filter(
                              (f) => f !== (item.id as number)
                            )
                          )
                      }
                    />
                    {isPDF(item) && (
                      <Button
                        size="xs"
                        marginTop="-2"
                        marginLeft="-22px"
                        leftIcon={<ViewIcon/>}
                        variant="ghost"
                        onClick={(e) => previewPDF(e, item, i)}
                        id={`${i}-item`}
                      >
                        {t('sampleDetailReport.preview')}
                      </Button>
                    )}
                    <img
                      src={
                        FILE_ICONS[item.fileType]
                      }
                      alt="file icon"
                      width={50}
                      style={{margin: 'auto'}}
                    />
                    <Spacer/>
                    <Text fontWeight="bold" fontSize="xs" mt={1}>
                      {oncoActText(item)}
                    </Text>

                    <Text fontWeight="bold" fontSize="xs" my={1}>
                      {bytes(item.sizeInBytes)}
                    </Text>
                    <Divider/>
                    {item.lastAccessTime && item.lastAccessedBy ? (
                      <>
                        <Text
                          fontWeight="bold"
                          fontSize="xs"
                          color="#304B99"
                          mt={1}
                        >
                          {t('sampleDetailReport.lastAccess')}:
                        </Text>
                        <Text fontWeight="bold" fontSize="xs">
                          {item.lastAccessedBy}
                        </Text>
                        <Text fontWeight="bold" fontSize="xs">
                          {parseDate(item.lastAccessTime as Date)}
                        </Text>
                        <Text fontWeight="bold" fontSize="xs">
                          {parseTime(item.lastAccessTime as Date)}
                        </Text>
                      </>
                    ) : (
                      <Text
                        fontWeight="bold"
                        fontSize="xs"
                        color="#304B99"
                        my={1}
                      >
                        {t('sampleDetailReport.unprocessed')}
                      </Text>
                    )}
                  </Box>
                </WrapItem>
              ))}
            </Wrap>

            <Accordion allowToggle={true}>
              <AccordionItem border="none" _hover={{}}>
                <AccordionButton _hover={{background: 'none'}}>
                  <Center flex={1} color="#304B99">
                    <Text
                      fontSize={14}
                      textDecoration="1px underline #304B99"
                    >
                      {t('sampleDetails.viewAllFiles')}
                    </Text>
                    <AccordionIcon ml="10%" fontSize={30}/>
                  </Center>
                </AccordionButton>

                <AccordionPanel pb={4} px={0} overflowX="scroll">
                  <TableComponent
                    columns={{
                      structure: Structures.BY_FOLDER,
                      texts: 'table.folderFiles',
                    }}
                    paginable={false}
                    data={Object.entries(otherFilesByFolder).map(
                      ([k, v]) => ({
                        type: k,
                        numberOfDocs: (v as { count }).count,
                        filesSize: (v as { size }).size,
                      })
                    )}
                    onClickCheckboxHeader={(isChecked) =>
                      isChecked
                        ? setFilesToDownload([
                          ...filesToDownload,
                          ...otherFiles.map((f) => f.id as number),
                        ])
                        : setFilesToDownload(
                          filesToDownload.filter(
                            (f) => !otherFiles.some((of) => f === of.id)
                          )
                        )
                    }
                    isCheckedCheckboxHeader={() =>
                      otherFiles.every((of) =>
                        filesToDownload.some((f) => f === of.id)
                      )
                    }
                    onClickCheckboxBody={(isChecked, _, folder) =>
                      isChecked
                        ? setFilesToDownload([
                          ...filesToDownload,
                          ...otherFiles
                            .filter((of) => of.folder === folder)
                            .map((f) => f.id as number),
                        ])
                        : setFilesToDownload(
                          filesToDownload.filter(
                            (f) => !otherFiles.some((of) => of.id === f)
                          )
                        )
                    }
                    isCheckedCheckboxBody={(id, folder) =>
                      otherFiles
                        .filter((of) => of.folder === folder)
                        .every((of) =>
                          filesToDownload.some((f) => f === of.id)
                        )
                    }
                    onClickIcon={(rowValues) => {
                      if (isRuoGermlineFolder(rowValues.type)) {
                        openGermlineWarning();
                      }
                      setIsToggled(!isToggled);
                      setToggledFolder(rowValues.type || '');
                    }}
                  />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </>
        )}
        <CloseIcon
          h="15"
          color="#304B99"
          cursor="pointer"
          position="absolute"
          top={1}
          right={5}
          onClick={onCloseSlider}
        />
        <ModalConfidentialInfo
          isOpen={isGermlineWarningOpen}
          onClose={closeGermlineWarning}
          message={t('modals.germlineWarning.text')}
        ></ModalConfidentialInfo>
        <FilePreview
          isOpen={isFilePreviewOpen}
          onClose={closeFilePreview}
          data={dataStore.componentPreview}
        />
      </Box>
    );
  }
);

export default ReportDetails;
