import {InfoIcon} from '@chakra-ui/icons';
import {Center, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Text,} from '@chakra-ui/react';

interface Props {
  isOpen: boolean;
  message: string;
  onClose(): void;
}

const ModalConfidentialInfo = ({isOpen, message, onClose}: Props) => {
  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay/>
      <ModalContent>
        <ModalCloseButton/>
        <ModalBody pt={6} pb={10}>
          <Flex direction={'column'} justifyContent="center">
            <Center>
              <InfoIcon
                background="#304B99"
                padding="10"
                borderRadius="100"
                color="white"
                boxSize={150}
              />
            </Center>
            <Text
              letterSpacing={0.5}
              color="#000000"
              align={'center'}
              fontSize="18"
              mt={5}
            >
              {message}
            </Text>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalConfidentialInfo;
