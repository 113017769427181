import {Box, Button, Flex, Spacer, Text, Wrap, WrapItem,} from "@chakra-ui/react";
import {observer} from "mobx-react-lite";
import {useTranslation} from "react-i18next";
import {Data} from "../../stores/data";
import {AlertConfirm} from "../Alerts/AlertConfirm";
import ModalReferenceSampleFinish from "../Modals/ModalReferenceSampleFinish";
import ModalSaving from "../Modals/ModalSaving";
import ReferenceSampleForm from "./components/ReferenceSampleForm";
import {useReferenceSampleRegistration} from "./hooks/useReferenceSampleRegistration";

export interface ReferenceSampleRegistrationProps {
  dataStore: Data;
}

export const ReferenceSampleRegistration: React.FC<ReferenceSampleRegistrationProps> =
  observer(({dataStore}) => {
    const [t] = useTranslation();

    const {
      isDisabledSubmit,
      setIsDisabledSubmit,
      savingModal,
      finish,
      cancel,
      referenceSampleFormRef,
    } = useReferenceSampleRegistration(dataStore);

    return (
      <Flex direction="column">
        <Flex direction="row" flex={1} bgColor="white">
          <Box marginX={5} marginY={4}>
            <Text
              textTransform="uppercase"
              letterSpacing={0.5}
              color="#5E5873"
              fontSize="18"
            >
              {t("table.actions.referenceSamples.title")}
            </Text>
          </Box>
        </Flex>
        <Box
          borderWidth="1px"
          overflowY="auto"
          height="72vh"
          background="#F6F7FC"
          css={{
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-track": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#304B99",
              borderRadius: "24px",
            },
          }}
          marginX={5}
          mb={5}
        >
          <ReferenceSampleForm
            innerRef={referenceSampleFormRef}
            dataStore={dataStore}
            onValidate={(isValid) => {
              setIsDisabledSubmit(!isValid);
            }}
          />
        </Box>

        <Flex minWidth="max-content" alignItems="center" gap="2">
          <Spacer/>
          <Wrap marginX="2" mt="2">
            <WrapItem h={12}>
              <Button
                color="#304B99"
                bg={"white"}
                boxShadow="md"
                variant="outline"
                borderColor="#304B99"
                fontSize={14}
                h={10}
                px={5}
                onClick={() => cancel.onOpenCancel()}
              >
                {t("referenceRegistrationProcess.actions.cancel")}
              </Button>
            </WrapItem>
            <WrapItem h={12}>
              <Button
                color="white"
                bg="#304B99"
                boxShadow="md"
                variant="solid"
                borderColor="gray.300"
                fontSize={14}
                h={10}
                px={5}
                disabled={isDisabledSubmit}
                onClick={async () => finish.onFinishButton()}
              >
                {t("referenceRegistrationProcess.actions.finish")}
              </Button>
            </WrapItem>
          </Wrap>
        </Flex>

        <AlertConfirm
          title={t("modals.cancel.title")}
          message={t("modals.cancel.message")}
          buttonCancel={t("modals.cancel.no")}
          actionButton={t("modals.cancel.yes")}
          isOpen={cancel.isOpenCancel}
          leastDestructiveRef={cancel.cancelRef}
          onClose={cancel.onCloseCancel}
          onAccept={cancel.onCancelProcess}
        />

        <ModalSaving
          title={t(savingModal.messageSaving)}
          isOpen={savingModal.isOpenModalSaving}
          loading={savingModal.saving}
        />

        <ModalReferenceSampleFinish
          isOpen={finish.isOpenModalFinish}
          onClose={finish.onCLoseModalFinish}
          onRegister={() => finish.onNewRegister()}
          onBack={() => cancel.onCancelProcess()}
        />
      </Flex>
    );
  });
