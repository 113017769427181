import {
  ChakraProvider,
  VStack,
  Grid,
  Spinner,
  Center,
  Box,
  extendTheme,
} from '@chakra-ui/react';
import { Logo } from './Logo';
import { Layout } from './Layout';
import { Auth } from './stores/auth';
import { observer } from 'mobx-react-lite';
import { Data } from './stores/data';
import { useIdleTimer } from 'react-idle-timer';
import { useEffect, useState } from 'react';

const Main = () => (
  <Box textAlign="center" fontSize="xl">
    <Grid p={3}>
      <VStack spacing={8}>
        <Logo h="40vmin" pointerEvents="none" />
      </VStack>
    </Grid>
  </Box>
);

const theme = extendTheme({
  components: {
    Checkbox: {
      baseStyle: {
        control: {
          borderColor: '#304B99',
          background: 'white',
          _checked: {
            _hover: {
              backgroundColor: 'white',
            },
            background: 'white',
            borderColor: '#304B99',
            color: '#304B99',
          },
        },
      },
    },
  },
});

const refreshUser = async (authStore: Auth): Promise<string> => {
  await authStore.refreshUser();
  return (authStore.user?.user as Record<string, any>)?.accessToken as string;
};

export const App = observer(({ auth }: { auth: Auth }) => {
  const [dataStore, setDataStore] = useState<Data | null>(null);

  useIdleTimer({
    onIdle: () => {
      auth.logOut();
    },
    timeout: 1200000,
  }); // 20 minutes - 1200000

  useEffect(() => {
    if (!dataStore && auth.user?.user.refreshToken) {
      setDataStore(
        new Data(
          (auth.user?.user as Record<string, any>)?.accessToken as string,
          async () => {
            return await refreshUser(auth);
          }
        )
      );
    }
  }, [auth.user]);

  if (!auth.user || !dataStore) {
    return (
      <ChakraProvider theme={theme}>
        <Center h="100vh">
          <Spinner size="xl" thickness="4px" />
        </Center>
      </ChakraProvider>
    );
  }

  return (
    <ChakraProvider theme={theme}>
      <Layout auth={auth} dataStore={dataStore}>
        <Main />
      </Layout>
    </ChakraProvider>
  );
});
