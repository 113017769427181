import bytes from "bytes";
import ProcessedIndicator from "./ProcessedIndicator";

import JSONIcon from "../assets/icons/json.png";
import PDFIcon from "../assets/icons/pdf.png";
import PATHIcon from "../assets/icons/path.png";
import TSVIcon from "../assets/icons/tsv.png";
import VCFIcon from "../assets/icons/vcf.png";
import XMLIcon from "../assets/icons/xml.png";
import DefaultIcon from "../assets/icons/default_file.png";
import {parseDate} from "../utils";
import {Img, Text} from "@chakra-ui/react";

export enum Structures {
  REPORTS = 'REPORTS',
  FILES = 'FILES',
  BY_FOLDER = 'BY_FOLDER',
  TUMOR_SAMPLES = 'TUMOR_SAMPLES',
  REFERENCE_SAMPLES = 'REFERENCE_SAMPLES',
}

export const FILE_ICONS = {
  JSON: JSONIcon,
  PDF: PDFIcon,
  TSV: TSVIcon,
  UNKNOWN: DefaultIcon,
  VCF: VCFIcon,
  XML: XMLIcon,
};

const REPORTS_COLUMNS = [
  {
    accessor: "processed",
    Cell: ({value}: { value: boolean }) => !value && <ProcessedIndicator/>,
  },
  {
    accessor: "reportedDate",
    Cell: ({value}: { value: string }) => value,
  },
  {
    accessor: "identifier",
  },
  {
    accessor: "pathologyNumber",
  },
  {
    accessor: "reportType",
  },
];

const BY_FOLDER_COLUMNS = [
  {
    accessor: "type",
    Cell: ({value}: { value: string }) => (
      <>
        <Img
          src={PATHIcon}
          alt="Folder icon"
          width="20px"
          marginBottom="-3.5"
        />
        <Text fontSize="sm" marginLeft="27px">
          {value}
        </Text>
      </>
    ),
  },
  {
    accessor: "numberOfDocs",
    Cell: ({value}: { value: number }) => `${value}`,
  },
  {
    accessor: "filesSize",
    Cell: ({value}: { value: number }) => `${bytes(value)}`,
  },
];

const FILES_COLUMNS = [
  {
    accessor: "fileType",
    Cell: ({value}: { value: string }) => (
      <img
        src={FILE_ICONS[value]}
        alt="File icon"
        width={40}
        style={{margin: "auto"}}
      />
    ),
  },
  {
    accessor: "name",
  },
  {
    accessor: "sizeInBytes",
    Cell: ({value}: { value: number }) => `${bytes(value)}`,
  },
  {
    accessor: "lastAccessedBy",
    Header: "Processed",
    Cell: ({row, value}) =>
      !value ? (
        "-"
      ) : (
        <>
          <p>{row.original.lastAccessedBy}</p>
          <p>{parseDate(value.lastAccessTime as Date)}</p>
        </>
      ),
  },
];

const TUMOR_SAMPLES_COLUMNS = [
  {
    accessor: 'startDate',
    Cell: ({value}) => (!value ? '-' : `${value}`),
  },
  {
    accessor: 'hospitalName',
    Cell: ({value}: { value: string }) => (!value ? '-' : value),
  },
  {
    accessor: 'submission',
    Cell: ({value}: { value: string }) => (!value ? '-' : value),
  },
  {
    accessor: 'id',
    Cell: ({value}: { value: string }) => (!value ? '-' : value),
  },
  {
    accessor: 'pathologyNumber',
    Cell: ({value}: { value: string }) => {
      return !value ? '-' : value;
    },
  },
  {
    accessor: 'isComplete',
    Cell: () => "-",
  },
];

const REFERENCE_SAMPLES_COLUMNS = [
  {
    accessor: 'startDate',
    Cell: ({value}: { value: Date }) => parseDate(value),
  },
  {
    accessor: 'hospitalName',
    Cell: ({value}: { value: string }) => (!value ? '-' : value),
  },
  {
    accessor: 'id',
    Cell: ({value}: { value: string }) => (!value ? '-' : value),
  },
];

const COLUMNS = {
  REPORTS: REPORTS_COLUMNS,
  FILES: FILES_COLUMNS,
  BY_FOLDER: BY_FOLDER_COLUMNS,
  TUMOR_SAMPLES: TUMOR_SAMPLES_COLUMNS,
  REFERENCE_SAMPLES: REFERENCE_SAMPLES_COLUMNS,
};

export const BUILD_COLUMNS = (
  columnsToRender: Structures,
  columnFields: Record<string, string>[]
) =>
  COLUMNS[columnsToRender].map((a) => ({
    ...a,
    Header: columnFields[a.accessor],
  }));
